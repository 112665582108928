import React from 'react';
import CrmLoader from 'components/common/CrmLoader';
import PropTypes from 'prop-types';

/**
 * react suspense wrapper with default fallback
 * @component
 */
const CrmSuspense = ({ children }) => {
  return <React.Suspense fallback={<CrmLoader hasBackdrop={false} loading z />}>{children}</React.Suspense>;
};

/** prop types */
CrmSuspense.propTypes = {
  /** children: required */
  children: PropTypes.node.isRequired,
};

export default CrmSuspense;
