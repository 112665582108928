import { useStore } from 'store/storeUtils';
import { useProjectID } from './useProjectID';
import { utilityGetDateWithTimezone } from 'utils/getDateWithTimezone';

export const useProjectClients = () => {
  const projectId = useProjectID();
  const store = useStore();
  const clients = store.projectClients[projectId] ? store.projectClients[projectId].data : [];

  return clients;
};

export const useClientsUpdatedAfter = () => {
  const projectId = useProjectID();
  const store = useStore();
  const zuluDate = store.projectClients[projectId] ? store.projectClients[projectId].updatedAfter : null;

  return utilityGetDateWithTimezone(new Date(zuluDate));
};
