import React from 'react';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import { calculateAptStatus } from 'utils/CalculateAptStatus';
import Capitalize from 'utils/Capitalize';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import appartamenti from 'images/assets/appartamenti.svg';
import NameFormatter from 'utils/NameFormatter';
import { DatesFormatter } from 'utils/DatesFormatter';
import { defaultSort } from 'utils/sort';

/**
 * configuration for the appartments list with the following columns:
 * - Appartamento (appartment name)
 * - Ultimo aggiornamento (last update, rendered as time from now)
 * - Taglio (capitalized appartment size)
 * - Valore appartamento (formatted appartment price)
 * - Status (appartment status in uppercase)
 */
const ListaPreventiviDashboardTableConfig = (store, t) => {
  return [
    {
      title: t('apartments.apartment'),
      field: 'name',
      customFilterAndSearch: (value, rowData) => {
        return rowData.name.toLowerCase().includes(value.toLowerCase()) || NameFormatter(rowData.name).includes(NameFormatter(value));
      },
      render: (rowData) => (
        <div style={{ display: 'flex' }}>
          <CrmSvgLoader data={appartamenti} alt='appartamenti' />
          <div className='black small-font-14 capitalize font-bold' style={{ paddingLeft: '8px', paddingTop: '4px' }}>
            {NameFormatter(rowData.name, { toLower: false })}
          </div>
        </div>
      ),
    },
    {
      title: t('apartments.lastUpdate'),
      field: 'updatedOn',
      customSort: (a, b) => defaultSort(a.updatedOn, b.updatedOn),
      customFilterAndSearch: (value, rowData) => {
        const date = DatesFormatter(new Date(rowData.updatedOn));
        return date.toLowerCase().includes(value.toLowerCase());
      },
      render: (rowData) => <>{DatesFormatter(new Date(rowData.updatedOn))}</>,
    },
    {
      title: t('apartments.typology'),
      field: 'plan.typology.name',
      customFilterAndSearch: (value, rowData) => {
        return t(`label.${rowData.plan.typology.name}`, {
          defaultValue: rowData.plan.typology.name,
        })
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      customSort(a, b) {
        const typologyA = a.plan.typology.name;
        const typologyB = b.plan.typology.name;
        const compare = t(`label.${typologyA}`, {
          defaultValue: typologyA,
        }).localeCompare(
          t(`label.${typologyB}`, {
            defaultValue: typologyB,
          }),
        );

        if (compare < 0) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (rowData) => <span className='capitalize'> {rowData.plan.typology ? t([`label.${rowData.plan.typology.name}`, rowData.plan.typology.name]) : ''}</span>,
    },
    {
      title: t('apartments.apartmentValue'),
      field: 'price',
      customFilterAndSearch: (value, rowData) => {
        return CurrencyFormatter(store.currency, rowData.price).includes(value.toLowerCase()) || ('' + rowData.price).includes(value.toLowerCase());
      },
      render: (rowData) => <span>{CurrencyFormatter(store.currency, rowData.price, rowData.forRent ? '/mese' : '')}</span>,
    },
    {
      title: t('apartments.status'),
      field: 'status',
      customFilterAndSearch: (value, rowData) => {
        var newStatus = calculateAptStatus(rowData.status, store.enabledStatus);
        return t(`label.${newStatus}`, {
          defaultValue: newStatus || '',
        })
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      customSort: (a, b) => {
        const statusA = calculateAptStatus(a.status, store.enabledStatus);
        const statusB = calculateAptStatus(b.status, store.enabledStatus);
        const compare = t(`label.${statusA}`, {
          defaultValue: statusA || '',
        }).localeCompare(
          t(`label.${statusB}`, {
            defaultValue: statusB || '',
          }),
        );

        if (compare < 0) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (rowData) => {
        var newStatus = calculateAptStatus(rowData.status, store.enabledStatus);
        return rowData.status && newStatus ? (
          <div style={{ display: 'flex' }}>
            <span className={'circle-in-table ' + `${rowData.status}`}></span>
            <span style={{ paddingLeft: '8px', paddingTop: '3px' }}>{t([`label.${Capitalize(newStatus)}`, newStatus])}</span>
          </div>
        ) : (
          ''
        );
      },
    },
  ];
};

export default ListaPreventiviDashboardTableConfig;
