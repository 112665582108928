export const quadrantCodeToArray = (code, defaultFloor) => {
  return code
    .replace(/\s/g, '')
    .split('+')
    .map((singleCode) => {
      if (!singleCode) {
        return [];
      }

      const formula = singleCode.split(':');
      const formulaPer = formula[0].split('*');
      const formulaPiano = singleCode.split('^');

      const multiplier = formulaPer.length > 1 ? parseFloat(formulaPer[0]) : 1.0;
      const quadrantCode = formulaPer.length > 1 ? formulaPer[1].split('^')[0] : formulaPer[0].split('^')[0];
      const divisor = formula.length > 1 ? parseFloat(formula[1].split('^')[0]) : 1.0;
      const floor = formulaPiano.length > 1 ? parseInt(formulaPiano[1].split(':')[0], 10) : defaultFloor;

      return {
        code: quadrantCode,
        part: multiplier / divisor,
        floor,
        fullCode: `${multiplier}*${quadrantCode}:${divisor}^${floor}`,
      };
    });
};

export const getFullQuadrantCode = (code, defaultFloor) => {
  const quadrantArray = quadrantCodeToArray(code, defaultFloor);

  return quadrantArray.map((q) => q.fullCode).join('+');
};

const checkIfValidCombination = (combinationQuadrants, quadrants) => {
  // 1. controllo che non ci siano quadranti duplicati
  const quadrantsMap = {}; // mappa utilizzata per controllare la disponibilità di ogni quadrante
  let totalSum = 0.0;

  // somma di tutte la availability dei quadranti
  const sumOfAllParts = quadrants.reduce((acc, curr) => acc + curr.available, 0);

  combinationQuadrants.forEach((accorpamento) => {
    accorpamento.forEach((quadrant) => {
      totalSum += quadrant.part;

      const quadrantKey = `${quadrant.code}^${quadrant.floor}`;
      if (quadrantsMap[quadrantKey]) {
        // c'è già il valore in mappa, sommo l'availability
        quadrantsMap[quadrantKey].availability += quadrant.part;
      } else {
        // creo il valore in mappa
        quadrantsMap[quadrantKey] = {
          code: quadrant.code,
          availability: quadrant.part,
          floor: quadrant.floor,
        };
      }
    });
  });

  // controllo se nella combinazione ogni quadrante ha usato TUTTO lo spazio disponibile
  let allQuadrantsSpaceUsed = true;
  Object.keys(quadrantsMap).forEach((quadrantKey) => {
    const quadrantInfo = quadrantsMap[quadrantKey];

    // TODO CROSS-PIANO: aggiungere filtro per piano
    const quadrant = quadrants.filter((q) => q.code === quadrantInfo.code && q.floor === quadrantInfo.floor)[0];

    if (!quadrant || quadrant.available !== quadrantInfo.availability) {
      allQuadrantsSpaceUsed = false;
    }
  });

  // 2. controllo anche che la somma dei quadranti ottenuti dalla combinazione copra tutto lo spazio disponibile sul piano/piani
  return allQuadrantsSpaceUsed && totalSum === sumOfAllParts;
};

const combinationToQuadrantArray = (piano, floorNumber) => {
  const quadrants = [];
  piano.forEach((accorpamento) => {
    quadrants.push(quadrantCodeToArray(accorpamento.code, floorNumber));
  });
  return quadrants;
};

// controlla se un accorpamento può essere aggiunta alla configurazione corrente del piano
const canBeAddedToCombination = (currentPiano, accorpamento, quadrants, floorNumber) => {
  const combinationQuadrants = combinationToQuadrantArray(currentPiano, floorNumber);

  return quadrantCodeToArray(accorpamento.code, floorNumber).every((quadrant) => {
    const currentQuadrant = quadrants.filter((q) => q.code === quadrant.code && q.floor === quadrant.floor)[0];
    const alreadyInPiano = combinationQuadrants.reduce(
      (accumulator, accorpamentoQuadranti) =>
        accumulator +
        accorpamentoQuadranti.reduce((acc, q) => {
          if (q.code === quadrant.code && q.floor === quadrant.floor) {
            return acc + q.part;
          }
          return 0;
        }, 0.0),
      0.0,
    );
    if (currentQuadrant && alreadyInPiano + quadrant.part <= currentQuadrant.available) {
      return true;
    }
    return false;
  });
};

const auxGenerateCombination = (currentPiano, accorpamenti, outputList, quadrants, floorNumber) => {
  if (accorpamenti.length === 0) {
    return;
  }

  const elementoAttuale = accorpamenti.shift();

  const canAdd = canBeAddedToCombination(currentPiano, elementoAttuale, quadrants, floorNumber);

  const currentPiano2 = [...currentPiano];
  currentPiano2.push({ code: elementoAttuale.code, price: elementoAttuale.price, aptOfInterest: elementoAttuale });

  if (checkIfValidCombination(combinationToQuadrantArray(currentPiano, floorNumber), quadrants) && combinationToQuadrantArray(currentPiano, floorNumber).length > 0) {
    outputList.push({ code: currentPiano.code, price: currentPiano.price, aptOfInterest: currentPiano });
  } else {
    auxGenerateCombination(currentPiano, [...accorpamenti], outputList, quadrants, floorNumber);
  }

  if (checkIfValidCombination(combinationToQuadrantArray(currentPiano2, floorNumber), quadrants) && combinationToQuadrantArray(currentPiano2, floorNumber).length > 0) {
    outputList.push(currentPiano2);
  } else if (canAdd) {
    auxGenerateCombination(currentPiano2, [...accorpamenti], outputList, quadrants, floorNumber);
  }
};

export const getCombinations = (accorpamenti, complexQuadrants, floorNumber, combinationsMap, buildingId) => {
  const copyAccorpamenti = [...accorpamenti];
  copyAccorpamenti.sort();

  const key = `${buildingId}=${copyAccorpamenti.join('=')}`;

  const copyCombinationsMap = combinationsMap;

  if (!combinationsMap[key]) {
    const combinations = generateValidCombinations(accorpamenti, complexQuadrants, floorNumber);
    copyCombinationsMap[key] = combinations;
  }
  return copyCombinationsMap[key];
};

export const getQuadrantsForCombinations = (accorpamenti, complexQuadrants, floorNumber) => {
  const quadrantiToReturn = [];
  const codesAlreadyInserted = [];

  accorpamenti.forEach((accorpamento) => {
    const quadrants = quadrantCodeToArray(accorpamento.code, floorNumber);

    quadrants.forEach((quadrant) => {
      complexQuadrants.forEach((cQuadrant) => {
        const quadrantKey = `${cQuadrant.code}^${cQuadrant.floor}`;
        if (cQuadrant.code === quadrant.code && quadrant.floor === cQuadrant.floor && !codesAlreadyInserted.includes(quadrantKey)) {
          quadrantiToReturn.push(cQuadrant);
          codesAlreadyInserted.push(quadrantKey);
        }
      });
    });
  });

  return quadrantiToReturn;
};

const generateValidCombinations = (accorpamenti, quadranti, floorNumber) => {
  const outputList = [];
  auxGenerateCombination([], accorpamenti, outputList, quadranti, floorNumber);
  return outputList;
};
