import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

/**
 * Svg loader
 * based on ReactSVG
 * Unknown props are passed down to the underlying component
 * @component
 * @see {@link https://www.npmjs.com/package/react-svg react-svg npm}
 */
const CrmSvgLoader = (props) => {
  const { data, className, alt, style, ...rest } = props;
  if (data) {
    return <ReactSVG style={style} alt={alt} src={data} className={className} {...rest} />;
  }
  return null;
};

CrmSvgLoader.defaultProps = {
  className: '',
  style: {},
  alt: 'image',
};

CrmSvgLoader.propTypes = {
  /** style: style for the container */
  style: PropTypes.object,
  /** className: container classname */
  className: PropTypes.string,
  /** data: svg to render */
  data: PropTypes.string.isRequired,
  /** alt: alternative label */
  alt: PropTypes.string,
};

export default CrmSvgLoader;
