import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { initI18n, useTranslation, loadResources } from '@tecma/i18n';
import { setBaseUrl } from '@tecma/bucket-lib';
import { datadogRum } from '@datadog/browser-rum';

import { useStore } from 'store/storeUtils.js';
import AggiornaConfermaPassword from 'pages/AggiornaConfermaPassword';
import PrivateRoute from 'routes/PrivateRoute';
import RouteConsts from './routes/Routes.jsx';
import Client from 'client/Client';
import DetectUrl from 'utils/DetectUrl';
import CrmLoader from 'components/common/CrmLoader';
import { PERMISSION } from 'constants/PagePermission.jsx';
import SSOComponent from 'client/SSOComponent';
import JwtChecker from 'utils/JwtChecker.js';
import LogoutListener from 'components/specific/LogoutListener.jsx';
import translation from './i18n/translation/en/translation.json';
import convertLanguage from 'utils/convertLanguage.jsx';
import inizializeAnalytics from 'utils/analytics.js';
import { SessionStore } from 'SessionStore.jsx';

if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    service: 'followup-sell',
    env: process.env.REACT_APP_NAMESPACE,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

export const i18n = initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: 'FollowUp',
  apiBase: process.env.REACT_APP_API_URI || '',
  expirationTime: 1000 * 60, // 1 minuto,
  cache: 'no-cache',
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);

export const App = () => {
  const store = useStore();
  const [loading, setLoading] = React.useState(true);
  const [i18nLoading, seti18nLoading] = React.useState(true);
  const prjInfo = useQuery(Client.GET_PROJECT_INFO, Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()));

  React.useEffect(async () => {
    if (prjInfo && prjInfo.data) {
      const project = { ...prjInfo.data.getProjectInfoByHost };
      store.setAssetsByObject(project);
      store.setDefaultLanguage(project.defaultLang);
      setLoading(false);
      await loadResources({
        id: project.id,
        displayName: project.displayName,
        languages: store.configLanguages,
      });
      seti18nLoading(false);
    }
  }, [prjInfo]);

  if (loading || i18nLoading) {
    return <CrmLoader hasBackdrop={false} loading={true} z={true} />;
  }

  return (
    <div className='wrapper'>
      <CrmLoader customClass='fixedPosition' loading={loading} hasBackdrop={false} z />
      <Router>
        <JwtChecker />
        <Switch>
          <Route path=':path?' component={() => <LayoutRoot />} />
        </Switch>
      </Router>
    </div>
  );
};

export const LayoutRoot = () => {
  const store = useStore();
  const { i18n } = useTranslation();
  const [jwtLoading, setJwtLoading] = React.useState(true);

  const prjInfo = useQuery(Client.GET_PROJECT_INFO, Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()));
  if (prjInfo && !prjInfo.loading && prjInfo.called && prjInfo.data) {
    if (prjInfo.data.getProjectInfoByHost === null) {
      throw new Error('Missing project info data');
    } else {
      store.setAssetsByObject({ ...prjInfo.data.getProjectInfoByHost });
      store.setBaseUrl(prjInfo.data.getProjectInfoByHost.baseurl);

      // Imposto il base url per la libreria bucket
      setBaseUrl(prjInfo.data.getProjectInfoByHost.baseurl || '');

      // set page title according to DB info (followup key)
      document.title = store.pageTitles.followup;
    }
  }

  const skipCondition = window.location.pathname.includes('resetPassword');
  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);

  React.useEffect(() => {
    if (userInfo && userInfo.data && userInfo.data.getUserByJWT) {
      setJwtLoading(false);
      const userLanguage = userInfo?.data?.getUserByJWT?.language;
      const userLocale = userInfo?.data?.getUserByJWT?.locale;
      store.setLoggedUserJwtData({ userData: userInfo.data.getUserByJWT, checkingJwt: false });
      store.setSystemLanguage(userLanguage);
      if (i18n.language.split('-')[0] !== (store.loggedUser.language || userLanguage) && userLanguage) {
        const convertedLanguage = convertLanguage(userLanguage, userLocale);
        i18n.changeLanguage(convertedLanguage);
        moment.locale(convertedLanguage);
      }
    } else if (userInfo?.error) {
      setJwtLoading(false);
    }
  }, [userInfo]);

  React.useEffect(() => {
    store.setCheckingJwt(true);
    if (store.projectId && !userInfo.called && !skipCondition) {
      loadUserInfo(Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId, skipCondition));
    }
  }, [store.projectId]);

  //define the first route available to redirect on update refresh
  const firstRoute = RouteConsts.sort((a, b) => a.order - b.order).find((el) => {
    const page = PERMISSION[el.id];
    const pagePermission = !page || (page && store.loggedUser && page.includes(store.loggedUser.role));
    if (pagePermission && el.id === 'home') {
      return el;
    } else if (pagePermission && el.navbar) {
      return el;
    }
  });

  //TODO: add some validation here and inform user if tenant is invalid
  return (
    <SessionStore>
      {store.baseUrl ? (
        <>
          {jwtLoading ? (
            <CrmLoader hasBackdrop={false} loading={true} z={true} />
          ) : (
            <Router basename={store.baseUrl}>
              <Switch>
                <Route exact path='/resetPassword' component={AggiornaConfermaPassword} />
                <Route exact path='/login' component={SSOComponent} />
                {/* TUTTE LE ROUTE, ORDINATE PER ORDER */}
                {RouteConsts.sort((a, b) => a.order - b.order).map((el) => {
                  const page = PERMISSION[el.id];
                  const pagePermission = !page || (page && store.loggedUser && page.includes(store.loggedUser.role));
                  return (
                    <PrivateRoute
                      breadcrumb={el.breadcrumb}
                      exact={el.exact}
                      key={el.order}
                      path={el.to}
                      ToRender={el.component}
                      title={el.title}
                      goBack={el.goBack}
                      navbar={store.enabledSections && store.enabledSections.length > 0 ? store.enabledSections.includes(el.id) && pagePermission : el.navbar && pagePermission}
                    />
                  );
                })}

                {/* DEFAULT */}
                <PrivateRoute path={firstRoute && firstRoute.to} ToRender={firstRoute && firstRoute.component} title={firstRoute && firstRoute.title} goBack={false} />
              </Switch>
              <LogoutListener />
            </Router>
          )}
        </>
      ) : null}
    </SessionStore>
  );
};
