import React from 'react';
import { observer } from 'mobx-react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from '@tecma/i18n';
import PropTypes from 'prop-types';

import Header from 'components/common/CrmHeader';
import CrmSuspense from 'components/common/CrmSuspense';
import Navbar from 'components/common/CrmNavbar';
import CrmGoBackButton from 'components/common/CrmGoBackButton';
import { useStore } from 'store/storeUtils.js';

import '../styles/pageTemplate.scss';

/**
 * Template generico della pagina
 * Contiene un Header, il contenuto della pagina circondato da un suspense
 * @component
 * @see CrmHeader
 * @see CrmSuspense
 */
const PageTemplate = observer((props) => {
  const { BodyComponent, title, goBack, setLanguage, ...rest } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const store = useStore();
  const { openDrawer } = store;
  const { t } = useTranslation();

  const pageName = t(title);
  let titleArray = pageName.split('/');

  return (
    <div className='pageTemplate'>
      <Header setLanguage={setLanguage} title={title} goBack={goBack} />
      <div className='pageTemplate-content'>
        <Navbar setLanguage={setLanguage} />
        <div className={typeof openDrawer === 'undefined' ? (matches === true ? 'body-wrapper-close' : 'body-wrapper-open') : openDrawer === true ? 'body-wrapper-open' : 'body-wrapper-close'}>
          <div className='page-title'>
            {goBack && <CrmGoBackButton className='page-back-button' />}
            {titleArray.length > 1 ? (
              <>
                <span>{titleArray[0] + ' / '}</span>
                <span>{titleArray[1]}</span>
              </>
            ) : (
              <span>{titleArray[0]}</span>
            )}
          </div>
          <CrmSuspense>
            <BodyComponent title={title} {...rest} />
          </CrmSuspense>
        </div>
      </div>
    </div>
  );
});

PageTemplate.propTypes = {
  breadcrumb: PropTypes.string,
  /** BodyComponent: required content */
  BodyComponent: PropTypes.elementType.isRequired,
  /** title: string. CrmHeader prop */
  title: PropTypes.string,
  /** goBack: boolean. CrmHeader prop */
  goBack: PropTypes.bool,

  setLanguage: PropTypes.func,
};

PageTemplate.defaultProps = {
  title: '',
  goBack: false,
};

export default PageTemplate;
