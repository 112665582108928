import ClientInput from 'models/ClientInput';
import { useHistory } from 'react-router-dom';
import { clientAddRoute } from 'routes/Routes';
import { useStore } from 'store/storeUtils';

export const useClients = () => {
  const store = useStore();
  const history = useHistory();

  const onClientCreate = () => {
    store.updateClientToAdd({ ...ClientInput.createClientInputFromStore(), notificationLanguage: store.defaultLanguage });
    store.setGlobalEditing(false);
    store.setRealLocation(clientAddRoute.to);
    history.push(clientAddRoute.to);
  };

  return { onClientCreate };
};
