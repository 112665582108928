import React from 'react';
import CrmSimpleAvatar from 'components/common/CrmSimpleAvatar';
import { DatesFormatter } from 'utils/DatesFormatter';
import { defaultSort } from 'utils/sort';

/**
 * configuration for the clients list with the following columns:
 * - #User (first and last name)
 * - Data registrazione (client creation date, rendered as time from now)
 * - Status (client status in uppercase)
 * - N. Appuntamenti Fatti (counter of done appointments)
 * - N. Preventivi richiesti (counter of done price quotations)
 */
const ListaClientiDashboardTableConfig = (t) => {
  return [
    {
      title: t('client.user'),
      field: 'userName',
      customSort: (a, b) => defaultSort(`${a.firstName} ${a.lastName}`.toLowerCase(), `${b.firstName} ${b.lastName}`.toLowerCase()),
      cellStyle: {
        padding: '12px',
      },
      customFilterAndSearch: (value, rowData) => {
        return rowData ? (rowData.firstName + ' ' + rowData.lastName).toLowerCase().includes(value.toLowerCase()) : false;
      },
      render: (rowData) =>
        rowData.firstName && rowData.lastName ? (
          <div className='flex-centered'>
            <CrmSimpleAvatar src={rowData.avatar}></CrmSimpleAvatar>

            <div className='black small-font-14 font-bold'>{rowData.firstName + ' ' + rowData.lastName}</div>
          </div>
        ) : (
          <CrmSimpleAvatar src={rowData.avatar}></CrmSimpleAvatar>
        ),
    },
    {
      title: t('client.createdOn'),
      field: 'createdOn',
      customFilterAndSearch: (value, rowData) => {
        const date = DatesFormatter(new Date(rowData.createdOn));
        return date.toLowerCase().includes(value.toLowerCase());
      },
      customSort: (a, b) => defaultSort(a.createdOn, b.createdOn),
      render: (rowData) => <>{DatesFormatter(new Date(rowData.createdOn))}</>,
    },
    {
      title: t('client.status'),
      field: 'status',
      render: (rowData) => <span className='capitalize'>{rowData.status}</span>,
    },
    {
      title: t('client.nAppuntamenti_'),
      field: 'nAppuntamenti',
    },
    {
      title: t('client.nPreventiviRichiesti'),
      field: 'nPreventiviRichiesti',
    },
  ];
};

export default ListaClientiDashboardTableConfig;
