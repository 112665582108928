import React from 'react';
import { Grid } from '@material-ui/core';
import Placeholder from 'images/assets/placeholder.svg';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import { useTranslation } from '@tecma/i18n';

const NoDataChartDashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item container justifyContent='center' alignItems='center' spacing={2} direction='column' style={{ padding: 10 }}>
        <Grid item>
          <CrmSvgLoader data={Placeholder} alt='Placeholder' />
        </Grid>
        <Grid item>{t('dashboard.das.NoDataInInterval')}</Grid>
      </Grid>
    </>
  );
};

export default NoDataChartDashboard;
