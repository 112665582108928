import colors from 'styles/colors.module.scss';

export const LIBERO = {
  status: 'libero',
  color: colors.graph_first_color,
  defaultLabel: 'Libero',
  labelId: 'dashboard.libero',
};
export const INTERESSE = {
  status: 'interesse',
  color: colors.graph_second_color,
  defaultLabel: 'Interesse',
  labelId: 'label.Interesse',
};
export const OPZIONATO = {
  status: 'opzionato',
  color: colors.graph_third_color,
  defaultLabel: 'Opzionato',
  labelId: 'label.Opzionato',
};
export const COMPROMESSO = {
  status: 'compromesso',
  color: colors.graph_fifth_color,
  defaultLabel: 'Compromesso',
  labelId: 'label.Compromesso',
};
export const PROPOSTA = {
  status: 'proposta',
  color: colors.graph_fourth_color,
  defaultLabel: 'Proposta',
  labelId: 'label.Proposta',
};
export const ROGITATO = {
  status: 'rogitato',
  color: colors.graph_sixth_color,
  defaultLabel: 'Rogitato',
  labelId: 'label.Rogitato',
};

export const ALL_STATUSES = [LIBERO, INTERESSE, OPZIONATO, COMPROMESSO, PROPOSTA, ROGITATO];
export const NOT_BOOKED_STATUSES = [LIBERO, INTERESSE, OPZIONATO];
export const BOOKED_STATUSES = [COMPROMESSO, PROPOSTA, ROGITATO];
