import CurrencyFormatter from 'utils/CurrencyFormatter.jsx';
import colors from 'styles/colors.module.scss';

const Statuses = {
  LIBERO: 'libero',
  INTERESSE: 'interesse',
  OPZIONATO: 'opzionato',
  PROPOSTA: 'proposta',
  COMPROMESSO: 'compromesso',
  ROGITATO: 'rogitato',
};

const hasPromo = [Statuses.OPZIONATO, Statuses.PROPOSTA, Statuses.COMPROMESSO, Statuses.ROGITATO];

const defaultColors = [colors.graph_first_color, colors.graph_second_color, colors.graph_third_color, colors.graph_fourth_color, colors.graph_fifth_color, colors.graph_sixth_color];
const availableStatus = Object.values(Statuses);
const availableSizes = ['Bilocale', 'Trilocale', 'Quadrilocale', 'Pentalocale Duplex'];
const shortNames = {
  monolocale: 'Mono',
  bilocale: 'Bilo',
  trilocale: 'Trilo',
  quadrilocale: 'Quadri',
  pentalocale: 'Penta',
  penthouse: 'Pent',
  'pentalocale duplex': 'Penta',
  duplex: 'Duplex',
};
const keyTofilter = 'status';
const borderColor = 'rgba(0,0,0,0)';
const horizontalBarChartOptions = {
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: { display: false },
        ticks: { beginAtZero: true },
        stacked: true,
      },
    ],
    yAxes: [{ gridLines: { display: false }, stacked: true }],
  },
  tooltips: {
    enabled: false,
  },
  responsive: true,
  maintainAspectRatio: false,
};
const doughnutChartOptions = (currency, locale) => {
  return {
    title: {
      display: false,
    },
    legend: {
      display: true,
      position: 'left',
      align: 'start',
      labels: {
        padding: 15,
        generateLabels(chart) {
          const data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map((label, i) => {
              const meta = chart.getDatasetMeta(0);
              const style = meta.controller.getStyle(i);

              return {
                text: `${label} (${CurrencyFormatter(currency, locale, data.datasets[0].data[i])})`,
                fillStyle: style.backgroundColor,
                strokeStyle: style.borderColor,
                lineWidth: style.borderWidth,
                index: i,
              };
            });
          }
          return [];
        },
      },
    },
    tooltips: {
      enabled: true,
    },
    responsive: true,
    maintainAspectRatio: false,
  };
};
const verticalBarChartOptions = {
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{ gridLines: { color: colors.BACKGROUNDS, display: false } }],
    yAxes: [{ gridLines: { color: colors.BACKGROUNDS, display: true } }],
  },
  tooltips: {
    enabled: true,
  },
  responsive: true,
  maintainAspectRatio: false,
};

const isDASProject = (apartments) => {
  return apartments.every((apartment) => !!apartment?.plan?.quadrants?.length);
};

const transformBookedNotBookedChart = (arrayOfApartments) => {
  const { LIBERO, INTERESSE, OPZIONATO } = Statuses;
  if (!arrayOfApartments || !Array.isArray(arrayOfApartments) | (!arrayOfApartments.length === 0)) {
    return [];
  }
  let bookedNumber = 0;
  let notBookedNumber = 0;
  let bookedValue = 0;
  let notBookedValue = 0;
  arrayOfApartments.forEach((appt) => {
    if (appt.status) {
      if ([LIBERO, INTERESSE, OPZIONATO].includes(appt.status)) {
        notBookedNumber = notBookedNumber + 1;
        notBookedValue = notBookedValue + appt.price;
      } else {
        //compromesso, proposta, rogito
        bookedNumber = bookedNumber + 1;
        bookedValue = bookedValue + appt.price;
      }
    }
  });
  return [
    { label: 'booked', data: bookedValue, total: bookedNumber, color: colors.booked },
    { label: 'notBooked', data: notBookedValue, total: notBookedNumber, color: colors.graph_first_color },
  ];
};

const transformToDoughnutChart = (availableStatuses, apartmentsArray, promosArray = [], featuresArray = []) => {
  const invalidStatusesArray = !availableStatuses || !Array.isArray(availableStatuses) || !availableStatuses.length === 0;
  const invalidapartmentsArray = !apartmentsArray || !Array.isArray(apartmentsArray) || !apartmentsArray.length === 0;

  if (invalidStatusesArray || invalidapartmentsArray) {
    return [];
  }
  let total = {};
  let value = {};
  let promos = {};

  availableStatus.forEach((as) => {
    total[as] = 0;
    value[as] = 0;
  });

  apartmentsArray.forEach((appt) => {
    total[appt.status] += 1;
    value[appt.status] += appt.price;
  });

  promosArray.forEach((promo) => {
    handleAddPromo(promo.status, promos, promo.promoPrice);
  });

  featuresArray.forEach((feature) => {
    total[feature.status] += 1;
    value[feature.status] += feature.price;
    handleAddPromo(feature.status, promos, feature.price);
  });

  return availableStatuses.map((as) => ({
    label: as,
    data: value[as],
    total: total[as],
    color: colors[as?.toUpperCase()] || '#000',
    promo: promos[as],
  }));
};

const handleAddPromo = (status, promoObj, value) => {
  if (hasPromo.includes(status)) {
    if (promoObj[status]) {
      promoObj[status] += value;
    } else {
      promoObj[status] = value;
    }
  }
};

const hexToRgba = (hex, opacity) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${Math.round(opacity * 100) / 100})`;
  }
  return null;
};
const myColorGenerator = (hexKey, numStep) => {
  const ratio = 1 / numStep;
  const rgbaColorArray = [];
  if (hexKey) {
    for (let i = 1; i <= numStep; i++) {
      const opacity = ratio * i;
      const resultRgba = hexToRgba(hexKey, opacity);
      rgbaColorArray.push(resultRgba);
    }
  }
  return rgbaColorArray;
};

const mapValueApartament = (apartmentDimension) => {
  let dimensionValue;
  switch (apartmentDimension.toLowerCase()) {
    case 'monolocale':
      dimensionValue = 1;
      break;
    case 'bilocale':
      dimensionValue = 2;
      break;
    case 'trilocale':
      dimensionValue = 3;
      break;
    case 'quadrilocale':
      dimensionValue = 4;
      break;
    case 'pentalocale':
      dimensionValue = 5;
      break;
    case 'plurilocale':
      dimensionValue = 6;
      break;
    default:
      dimensionValue = 6;
  }
  return dimensionValue;
};
const compareApartmentByDimension = (a, b) => {
  const aValue = mapValueApartament(a.label);
  const bValue = mapValueApartament(b.label);
  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};

const transformToDimensionDoughnutChart = (array, hexColor) => {
  const arrayOfDimension = [];
  array.forEach((apt) => {
    const planElement = apt.plan;
    if (planElement.typology) {
      const indexOfInterest = arrayOfDimension.findIndex((el) => el.label === planElement.typology.name);
      if (indexOfInterest > -1) {
        const elementToSubstitute = { ...arrayOfDimension[indexOfInterest] };
        elementToSubstitute.total = elementToSubstitute.total + 1;
        elementToSubstitute.data = elementToSubstitute.data + apt.price;
        arrayOfDimension[indexOfInterest] = elementToSubstitute;
      } else {
        arrayOfDimension.push({
          color: '',
          total: 1,
          data: apt.price,
          label: planElement.typology.name,
        });
      }
    }
  });
  arrayOfDimension.sort(compareApartmentByDimension);
  const myArrayOfColors = myColorGenerator(hexColor, arrayOfDimension.length);
  arrayOfDimension.map((el, i) => {
    el.color = myArrayOfColors[i];
  });
  return arrayOfDimension;
};

const ChartConstants = {
  defaultColors,
  availableStatus,
  availableSizes,
  shortNames,
  keyTofilter,
  borderColor,
  horizontalBarChartOptions,
  verticalBarChartOptions,
  doughnutChartOptions,
  transformBookedNotBookedChart,
  transformToDoughnutChart,
  isDASProject,
  transformToDimensionDoughnutChart,
};

export default ChartConstants;
