import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { defaultInputProps, defaultInputLabelProps } from 'constants/InputConstants.jsx';
import { InputAdornment, Tooltip } from '@material-ui/core';
import { Trans, useTranslation } from '@tecma/i18n';
import clsx from 'clsx';
import IconWithTooltip from 'components/specific/IconWithTooltip';
import CrmSvgLoader from 'components/common/CrmSvgLoader';

import 'styles/input.scss';

const TRANSLATIONS_TAGS_MAPPING = { b: <b />, br: <br /> };

/**
 * Default input
 * based on material-ui TextField
 * Unknown props are passed down to the underlying MUI component
 * @component
 * @see {@link https://material-ui.com/api/text-field/ Text Field API}
 */
const CrmInput = (props) => {
  const { t } = useTranslation();
  const {
    defaultInput,
    extraClass,
    label,
    extraInfo,
    required,
    disabled,
    variant,
    value,
    extraInputLabelProps,
    extraInputProps,
    defaultValue,
    minLength,
    maxLength,
    min,
    max,
    error,
    valueIcon,
    valueIconTooltipLabel,
    valueIconTooltipValues,
    tooltip,
    ...rest
  } = props;

  let mergedInputLabelProps;
  if (extraInputLabelProps && extraInputLabelProps !== {} && extraInputLabelProps.classes) {
    mergedInputLabelProps = { ...extraInputLabelProps };
    Object.keys(defaultInputLabelProps.classes).forEach((key) => {
      mergedInputLabelProps.classes[key] = mergedInputLabelProps.classes[key] + ' ' + defaultInputLabelProps.classes[key];
    });
  } else {
    mergedInputLabelProps = defaultInputLabelProps;
  }
  const dimension = {};
  if (minLength) dimension['minLength'] = minLength;
  if (maxLength) dimension['maxLength'] = maxLength;
  if (min) dimension['min'] = min;
  if (max) dimension['max'] = max;

  let mergedInputProps;
  if (extraInputProps && extraInputProps !== {} && extraInputProps.classes) {
    mergedInputProps = { ...extraInputProps };
    Object.keys(defaultInputProps.classes).forEach((key) => {
      mergedInputProps.classes[key] = mergedInputProps.classes[key] + ' ' + defaultInputProps.classes[key];
    });
  } else {
    mergedInputProps = defaultInputProps;
  }

  const adornment = valueIcon ? (
    valueIconTooltipLabel ? (
      <InputAdornment position='start'>
        <IconWithTooltip className='crm-input-icon' data={valueIcon} title={<Trans i18nKey={valueIconTooltipLabel} components={TRANSLATIONS_TAGS_MAPPING} values={valueIconTooltipValues} />} />
      </InputAdornment>
    ) : (
      <InputAdornment position='start'>
        <CrmSvgLoader className='crm-input-icon' data={valueIcon} {...rest} />
      </InputAdornment>
    )
  ) : undefined;

  return (
    <>
      {extraInfo ? (
        <TextField
          variant={variant}
          label={
            <div>
              {label}
              <span className='text_extra_info'>{t('apartments.extrainfo')}</span>
            </div>
          }
          error={error}
          required={required}
          disabled={disabled}
          value={value === 0 || value ? value : value !== '' && defaultValue ? defaultValue : ''}
          InputLabelProps={defaultInput ? props.extraInputLabelProps : mergedInputLabelProps}
          InputProps={{
            ...(defaultInput ? props.extraInputProps : mergedInputProps),
            startAdornment: adornment,
          }}
          className={clsx('crm-input', extraClass, 'extrainfospace')}
          inputProps={dimension}
          {...rest}
        />
      ) : (
        <Tooltip title={value} disableHoverListener={!tooltip || !disabled} disableFocusListener disableTouchListener className='textfield-input' placement='top-start'>
          <TextField
            variant={variant}
            label={label}
            error={error}
            required={required}
            disabled={disabled}
            value={value === 0 || value ? value : value !== '' && defaultValue ? defaultValue : ''}
            InputLabelProps={defaultInput ? props.extraInputLabelProps : mergedInputLabelProps}
            InputProps={{
              ...(defaultInput ? props.extraInputProps : mergedInputProps),
              startAdornment: adornment,
            }}
            className={clsx('crm-input', extraClass)}
            inputProps={dimension}
            {...rest}
          />
        </Tooltip>
      )}
    </>
  );
};

CrmInput.defaultProps = {
  variant: 'standard',
  extraClass: '',
  label: '',
  required: false,
  defaultInput: false,
  disabled: false,
};

/** prop types */
CrmInput.propTypes = {
  /** defaultInput: removes custom css overrides */
  defaultInput: PropTypes.bool,
  /** extraInputLabelProps: additional props for the label */
  extraInputLabelProps: PropTypes.object,
  /** InputProps: additional props for the underlying Input component */
  extraInputProps: PropTypes.object,
  /** extraClass: additional className */
  extraClass: PropTypes.string,
  /** value: string or number */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** label: for the input */
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** variant: string, based on material-ui input variants. Must be one of ["standard", "filled", "outlined"] */
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  /** required: boolean, required input */
  required: PropTypes.bool,
  /** disabled: boolean, disabled input */
  disabled: PropTypes.bool,
};

export default CrmInput;
