/** creates a VisitedAppartmentInput using all passed parameters */
const createVisitedAppartmentInputFromScratch = (apparment, status, label) => {
    return {
        appartment: apparment,
        status: status,
        label: label
    };
};

/** creates a list of VisitedAppartmentInput using all passed parameters */
const createVisitedAppartmentListInputFromScratch = (list) => {
    if( !list || list.length === 0 ) { return [] }
    return list.map((el) => createVisitedAppartmentInputFromScratch(el.appartment, el.status) );
};

/** creates a VisitedAppartmentInput using a fixed status and an object */
const createVisitedAppartmentListInputFromObjAndString = (obj, status) => {
    if (!obj) { return [] }
    return createVisitedAppartmentInputFromScratch(obj.value, status, obj.label);
};

/** creates a list of VisitedAppartmentInput using a fixed status and a list of values and labels */
const createVisitedAppartmentListInputFromListAndString = (list, status) => {
    if (!list || list.length === 0) { return [] }
    return list.map((el) => createVisitedAppartmentInputFromScratch(el.value, status, el.label));
};

/** creates a VisitedAppartmentInput using an object */
const createVisitedAppartmentInputFromStore = (obj) => {
    if (!obj) { return {} }
    return {
        appartment: obj.appartment,
        status: obj.status
    };
};

/** creates a list of VisitedAppartmentInput using a list of object */
const createVisitedAppartmentListInputFromStore = (obj) => {
    if (!obj || obj.length === 0) { return [] }
    return obj.map((el) => createVisitedAppartmentInputFromStore(el));
};

/** creates a list of VisitedAppartmentInput using a list of object */
const toVisitedAppartmentList = (list) => {
    return createVisitedAppartmentListInputFromStore(list); 
}

/** creates a VisitedAppartmentInput using a list of Appartment objects and a fixed status */
const createVisitedAppartmentFromFilteredAppartments = (filtered, status) => {
    if (!filtered || filtered.length === 0) { return [] }
    return filtered.map((el) => {
        return {
            appartment: el.id,
            status: status,
            label: el.name
        };
    });
}

/** creates a VisitedAppartmentInput using a list of Appartment objects */
const createVisitedAppartmentFromFilteredAppartments2 = (filtered) => {
    if (!filtered || filtered.length === 0) { return [] }
    return filtered.map((el) => {
        return {
            appartment: el.id,
            status: el.status,
            label: el.name
        };
    });
}

const VisitedAppartmentInput = {
    createVisitedAppartmentInputFromScratch,
    createVisitedAppartmentListInputFromScratch,
    createVisitedAppartmentListInputFromObjAndString,
    createVisitedAppartmentListInputFromListAndString,
    createVisitedAppartmentInputFromStore,
    createVisitedAppartmentListInputFromStore,
    toVisitedAppartmentList,
    createVisitedAppartmentFromFilteredAppartments,
    createVisitedAppartmentFromFilteredAppartments2
};

export default VisitedAppartmentInput;