import { memo } from 'react';
import { useIsProperty, useIsAccountManager } from 'store/hooks/useIsProperty';
import { IconAddEvent } from './IconAddEvent';
import { useClients } from 'store/hooks/useClients';
import { useCalendar } from 'store/hooks/useCalendar';
import { IconAddClient } from './IconAddClient';
import { ButtonShortcut } from './ButtonShortcut';
import { useTranslation } from '@tecma/i18n';
import './Menu.css';

export const Menu = memo(() => {
  const isProperty = useIsProperty();
  const isAccountManager = useIsAccountManager();
  const { onClientCreate } = useClients();
  const { onEventCreate } = useCalendar();
  const { t } = useTranslation();

  if (isProperty || isAccountManager) return null;

  return (
    <section className='menu-section'>
      <h3 className='menu-title'>{t('home.menu.title')}</h3>
      <div className='menu-grid'>
        <div className='menu-box'>
          <div className='menu-icon'>
            <IconAddClient />
          </div>
          <div className='menu-item'>
            <div>
              <div className='menu-item-title'>{t('home.shortcut.client')}</div>
              <div>{t('home.shortcut.client.button')}</div>
            </div>
            <div>
              <ButtonShortcut onClick={onClientCreate} label={t('home.addClient')} />
            </div>
          </div>
        </div>

        <div className='menu-box'>
          <div className='menu-icon'>
            <IconAddEvent />
          </div>
          <div className='menu-item'>
            <div>
              <div className='menu-item-title'>{t('home.shortcut.calendar')}</div>
              <div>{t('home.shortcut.calendar.button')}</div>
            </div>
            <div>
              <ButtonShortcut onClick={onEventCreate} label={t('home.addEvent')} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
