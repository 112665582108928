import React from 'react';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import { calculateAptStatus } from 'utils/CalculateAptStatus';
import Icon from '@material-ui/core/Icon';
import NameFormatter from 'utils/NameFormatter';
import { DatesFormatter } from 'utils/DatesFormatter';
import { defaultSort } from 'utils/sort';
import colors from '../styles/colors.module.scss';

const ListaMixTipologicoTableConfig = (store, t) => {
  return [
    {
      title: t('apartments.apartment'),
      field: 'name',
      customFilterAndSearch: (value, rowData) => {
        return rowData.name.toLowerCase().includes(value.toLowerCase()) || NameFormatter(rowData.name).includes(NameFormatter(value));
      },
      customSort: (a, b) => {
        const apartmentA = a.name;
        const apartmentB = b.name;
        const compare = t(`label.${apartmentA}`, { defaultValue: apartmentA }).localeCompare(t(`label.${apartmentB}`, { defaultValue: apartmentB }), undefined, { numeric: true });

        if (compare < 0) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (rowData) => (
        <div
          className='flex-centered black small-font-14 capitalize font-bold'
          style={{
            paddingLeft: '8px',
          }}
        >
          {NameFormatter(rowData.name, { toLower: false })}
        </div>
      ),
    },
    {
      title: t('apartments.lastUpdate'),
      field: 'updatedOn',
      customSort: (a, b) => defaultSort(a.updatedOn, b.updatedOn),
      customFilterAndSearch: (value, rowData) => {
        const date = DatesFormatter(new Date(rowData.updatedOn));
        return date.toLowerCase().includes(value.toLowerCase());
      },
      render: (rowData) => <>{DatesFormatter(new Date(rowData.updatedOn))}</>,
    },
    {
      title: t('apartments.apartmentValue'),
      field: 'price',
      customFilterAndSearch: (value, rowData) => {
        return CurrencyFormatter(store.currency, rowData.price).includes(value.toLowerCase()) || ('' + rowData.price).includes(value.toLowerCase());
      },
      render: (rowData) => <span>{CurrencyFormatter(store.currency, rowData.price, rowData.forRent ? '/mese' : '')}</span>,
    },
    {
      title: t('apartments.available'),
      field: 'oldAvailable',
      render: (rowData) => {
        const available = rowData.oldAvailable;
        return (
          <div data-testid='typological-mix-available'>
            {available ? <Icon style={{ color: colors.accent, fontSize: '24px' }}>check</Icon> : <Icon style={{ color: '#F35959', fontSize: '24px' }}>clear</Icon>}
          </div>
        );
      },
    },
    {
      title: t('apartments.status'),
      field: 'newStatus',
      customFilterAndSearch: (value, rowData) => {
        const newStatus = calculateAptStatus(rowData.status, store.enabledStatus);

        return t(`label.${newStatus}`, { defaultValue: newStatus || '' })
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      customSort: (a, b) => {
        const statusA = calculateAptStatus(a.status, store.enabledStatus);
        const statusB = calculateAptStatus(b.status, store.enabledStatus);
        const compare = t(`label.${statusA}`, { defaultValue: statusA || '' }).localeCompare(t(`label.${statusB}`, { defaultValue: statusB || '' }));

        if (compare < 0) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (rowData) => {
        var newStatus = calculateAptStatus(rowData.status, store.enabledStatus);
        return rowData.status && newStatus ? (
          <div style={{ display: 'flex' }}>
            <span className={'circle-in-table ' + `${rowData.status}`} />
            <span style={{ paddingLeft: '8px', paddingTop: '3px' }}>{t([`label.${newStatus}`, newStatus])}</span>
          </div>
        ) : (
          ''
        );
      },
    },
  ];
};

export default ListaMixTipologicoTableConfig;
