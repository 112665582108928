import { observable, action, computed, makeObservable } from 'mobx';
import Initials from 'utils/Initials';
import ValidateEmail from 'utils/ValidateEmail';
import ValidatePhone from 'utils/ValidatePhone';
import Capitalize from 'utils/Capitalize';
import moment from 'utils/MyMoment';
import { isEqual, omit } from 'lodash';
import { alpha2ToAlpha3T } from '@cospired/i18n-iso-languages';
import { makePersistable } from 'mobx-persist-store';
import convertLanguage from 'utils/convertLanguage';
import { getCustomColumnsTableByProjectAndClient as getCustomColumnsTable, setCustomColumnsTableByProjectAndClient as setCustomColumnsTable } from 'utils/customTable';
import { datadogRum } from '@datadog/browser-rum';

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */
class Store {
  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.feKey = '';
    this.feVendorKey = '';
    this.breadCrumbs = null;
    this.loginProjectName = '';
    this.assetsUrl = null;
    this.searchAppartamenti = '';
    this.searchAsset = '';
    this.searchAppartamentiHome = '';
    this.searchClientiHome = '';
    this.searchClienti = '';
    this.hostKey = '';
    this.uploadBulkLoader = false;
    this.bulkDone = false;
    this.bulkError = false;
    this.projectName = null;
    this.enabledStatus = null;
    this.projectId = null;
    this.typologies = [];
    this.enabledSections = [];
    this.languages = undefined;
    this.projectLanguages = undefined;
    this.dashboardConfig = undefined;
    this.planInfo = undefined;
    this.policyConfigObj = [];
    this.currentNavTab = 0;
    this.apartmentId = 0;
    this.clientId = 0;
    this.vendorEmail = '';
    this.vendorPassword = '';
    this.errorModal = false;
    this.openTwoFAModal = false;
    this.openNewsletterModal = false;
    this.saveModal = false;
    this.saveErrorModal = false;
    this.saveInterestModal = false;
    this.errorMessage = null;
    this.logo = [];
    this.uploadBulkDataModal = [];
    this.eventId = null;
    this.assetLogin = [];
    this.externalPdf = null;
    this.menuMobile = false;
    this.avatarMenu = false;
    this.appartmentModal = false;
    this.leadScoreInfoModal = false;
    this.addActionModal = false;
    this.openTagHandler = false;
    this.updatingAction = null;
    this.deletingAction = false;
    this.featureModal = false;
    this.addingAppartments = false;
    this.removeAppartmentModal = false;
    this.removeFeatureModal = false;
    this.removeClientModal = false;
    this.deleteProposalModal = false;
    this.modifyingAppartmentOriginalState = null;
    this.removeAppartment = null;
    this.removeFeature = null;
    this.modifyingAppartment = {
      id: '',
      name: '',
      status: '',
    };
    this.statusProposal = null;
    this.confirmProposalModal = false;
    this.signProposalModal = false;
    this.validateProposalModal = false;
    this.refuseProposalModal = false;
    this.addInfoConfirmModal = false;
    this.tabConfirmModal = false;
    this.errorImportApartment = null;
    this.errorImportApartmentModal = false;
    this.errorUploadPlanimetry = null;
    this.errorUploadPlanimetryModal = false;
    this.expiredQuote = null;
    this.expiredProposal = null;
    this.renewTimeQuote = null;
    this.expiredQuoteModal = false;
    this.expiredProposalModal = false;
    this.renewTimeQuoteModal = false;
    this.deleteQuote = null;
    this.deleteQuoteModal = false;
    this.createAnotherAppointmentModal = false;
    this.lastCreatedAction = {};
    this.removeEvent = false;
    this.updateEvent = false;
    this.cancelEvent = false;
    this.eventList = [];
    this.event = undefined;
    this.currentEvent = {};
    this.updEvent = false;
    this.calendarPopup = false;
    this.snackbarOpen = false;
    this.snackbarMessage = undefined;
    this.snackbarError = false;
    this.snackbarAmberOpen = false;
    this.clientToAdd = null;
    this.openClient = null;
    this.selectedClient = null;
    this.clientToSave = null;
    this.appartmentToSave = null;
    this.apartmentInformation = null;
    this.selectedAppartment = null;
    this.selectedAppartmentClient = null;
    this.loggedUser = null;
    this.loggedToken = null;
    this.realLocation = undefined;
    this.addTagLink = false;
    this.actionLink = false;
    this.longLoading = false;
    this.rowLoading = false;
    this.globalEditing = false;
    this.infoEditing = false;
    this.pdfToView = undefined;
    this.project_id = undefined;
    this.vendorLogo = [];
    this.locale = null;
    this.currency = null;
    this.pageTitles = undefined;
    this.clients = undefined;
    this.appartments = undefined;
    this.users = undefined;
    this.userCalled = undefined;
    this.clientsFromFilter = null;
    this.clientsFromFilterDashboard = null;
    this.clientsToFilter = null;
    this.clientsToFilterDashboard = null;
    this.appointmentConfig = null;
    this.appartamentoSort = [null, null, null, null, null, null, null, null, null];
    this.assetSort = [null, null, null, null, null, null];
    this.clienteSort = [null, null, null, null, null];
    this.clienteMyHomeSort = [null, null, null, null, null];
    this.clienteCRMSort = [null, null, null, null, null];
    this.customTableSort = {};
    this.systemLanguage = null;
    this.defaultLanguage = null;
    this.enabledTools = null;
    this.myhomeConfig = undefined;
    this.myhomeProDisabled = undefined;
    this.openDrawer = undefined;
    this.setItemModal = false;
    this.openAccountModal = false;
    this.openSharePlanimetriaModal = false;
    this.openReserveTimeModal = false;
    this.deactivateAccountModal = false;
    this.stepperModal = false;
    this.activeMyHomeModal = false;
    this.deleteMyHomeModal = false;
    this.calendarSettingsModal = false;
    this.filterClientSettingsModal = false;
    this.errorCalendarSettings = false;
    this.updateAdditionalInfo = null;
    this.pertinenze = undefined;
    this.selectedPertinenza = undefined;
    this.pertinenzaId = undefined;
    this.pertinenzaToSave = null;
    this.calendarSyncRevokeSuccess = undefined;
    this.tabValueApartment = 0;
    this.customColumnsTable = {};
    this.tabValueClient = 0;
    this.paymentByClientModal = false;
    this.openDocumentModal = false;
    this.openProposalModal = false;
    this.proposalDocPath = null;
    this.documentProposalToUpload = null;
    this.quote_ids = [];
    this.sharedDocumentModal = false;
    this.checkingJwt = false;
    this.retryPaymentModal = false;
    this.proposalToDelete = undefined;
    this.redirectionToSsoNeeded = false;
    this.baseUrl = undefined;
    this.actionTimelineSelectedYear = null;
    this.actionTimelineSelectedMonth = null;
    this.homeConfiguratorConfig = undefined;
    this.automaticQuoteEnabled = false;
    this.installment = {};
    this.openEnableModifyHCModal = false;
    this.selectedCustomization = {};
    this.storeAddress = '';
    this.defaultLang = '';
    this.projectFlags = null;
    this.calendarFilters = {};
    this.projectClients = {};
    this.loadingClients = false;

    makeObservable(this, {
      loadingClients: observable,
      feKey: observable,
      feVendorKey: observable,
      breadCrumbs: observable,
      loginProjectName: observable,
      assetsUrl: observable,
      searchAppartamenti: observable,
      searchAsset: observable,
      searchAppartamentiHome: observable,
      searchClientiHome: observable,
      searchClienti: observable,
      hostKey: observable,
      uploadBulkLoader: observable,
      bulkDone: observable,
      bulkError: observable,
      projectName: observable,
      enabledStatus: observable,
      projectId: observable,
      typologies: observable,
      enabledSections: observable,
      languages: observable,
      projectLanguages: observable,
      dashboardConfig: observable,
      planInfo: observable,
      policyConfigObj: observable,
      currentNavTab: observable,
      apartmentId: observable,
      clientId: observable,
      vendorEmail: observable,
      vendorPassword: observable,
      errorModal: observable,
      openTwoFAModal: observable,
      openNewsletterModal: observable,
      errorMessage: observable,
      logo: observable,
      uploadBulkDataModal: observable,
      eventId: observable,
      assetLogin: observable,
      externalPdf: observable,
      menuMobile: observable,
      avatarMenu: observable,
      appartmentModal: observable,
      leadScoreInfoModal: observable,
      addActionModal: observable,
      openTagHandler: observable,
      updatingAction: observable,
      deletingAction: observable,
      featureModal: observable,
      addingAppartments: observable,
      removeAppartmentModal: observable,
      removeFeatureModal: observable,
      removeClientModal: observable,
      modifyingAppartmentOriginalState: observable,
      removeAppartment: observable,
      removeFeature: observable,
      modifyingAppartment: observable,
      statusProposal: observable,
      confirmProposalModal: observable,
      signProposalModal: observable,
      validateProposalModal: observable,
      refuseProposalModal: observable,
      addInfoConfirmModal: observable,
      tabConfirmModal: observable,
      errorImportApartment: observable,
      errorImportApartmentModal: observable,
      errorUploadPlanimetry: observable,
      errorUploadPlanimetryModal: observable,
      expiredQuote: observable,
      expiredProposal: observable,
      renewTimeQuote: observable,
      expiredQuoteModal: observable,
      expiredProposalModal: observable,
      renewTimeQuoteModal: observable,
      deleteQuote: observable,
      deleteQuoteModal: observable,
      createAnotherAppointmentModal: observable,
      lastCreatedAction: observable,
      removeEvent: observable,
      updateEvent: observable,
      cancelEvent: observable,
      eventList: observable,
      event: observable,
      currentEvent: observable,
      updEvent: observable,
      calendarPopup: observable,
      snackbarOpen: observable,
      snackbarAmberOpen: observable,
      snackbarMessage: observable,
      snackbarError: observable,
      clientToAdd: observable,
      selectedClient: observable,
      openClient: observable,
      clientToSave: observable,
      appartmentToSave: observable,
      apartmentInformation: observable,
      selectedAppartment: observable,
      selectedAppartmentClient: observable,
      loggedUser: observable,
      loggedToken: observable,
      realLocation: observable,
      addTagLink: observable,
      actionLink: observable,
      longLoading: observable,
      rowLoading: observable,
      globalEditing: observable,
      infoEditing: observable,
      pdfToView: observable,
      project_id: observable,
      vendorLogo: observable,
      locale: observable,
      currency: observable,
      pageTitles: observable,
      clients: observable,
      appartments: observable,
      users: observable,
      userCalled: observable,
      clientsFromFilter: observable,
      clientsToFilter: observable,
      appointmentConfig: observable,
      appartamentoSort: observable,
      assetSort: observable,
      clienteSort: observable,
      clienteMyHomeSort: observable,
      clienteCRMSort: observable,
      customTableSort: observable,
      systemLanguage: observable,
      defaultLanguage: observable,
      enabledTools: observable,
      myhomeConfig: observable,
      myhomeProDisabled: observable,
      openDrawer: observable,
      setItemModal: observable,
      openAccountModal: observable,
      openSharePlanimetriaModal: observable,
      openReserveTimeModal: observable,
      deactivateAccountModal: observable,
      stepperModal: observable,
      activeMyHomeModal: observable,
      deleteMyHomeModal: observable,
      calendarSettingsModal: observable,
      filterClientSettingsModal: observable,
      errorCalendarSettings: observable,
      updateAdditionalInfo: observable,
      pertinenze: observable,
      selectedPertinenza: observable,
      pertinenzaId: observable,
      pertinenzaToSave: observable,
      calendarSyncRevokeSuccess: observable,
      tabValueApartment: observable,
      customColumnsTable: observable,
      tabValueClient: observable,
      paymentByClientModal: observable,
      openDocumentModal: observable,
      openProposalModal: observable,
      proposalDocPath: observable,
      documentProposalToUpload: observable,
      quote_ids: observable,
      sharedDocumentModal: observable,
      checkingJwt: observable,
      retryPaymentModal: observable,
      redirectionToSsoNeeded: observable,
      proposalToDelete: observable,
      deleteProposalModal: observable,
      saveModal: observable,
      saveErrorModal: observable,
      saveInterestModal: observable,
      clientsFromFilterDashboard: observable,
      clientsToFilterDashboard: observable,
      baseUrl: observable,
      actionTimelineSelectedYear: observable,
      actionTimelineSelectedMonth: observable,
      homeConfiguratorConfig: observable,
      automaticQuoteEnabled: observable,
      installment: observable,
      openEnableModifyHCModal: observable,
      selectedCustomization: observable,
      storeAddress: observable,
      defaultLang: observable,
      projectFlags: observable,

      setUploadBulkDataModal: action,
      setBulkDone: action,
      setUploadBulkLoader: action,
      setPdfToView: action,
      setGlobalEditing: action,
      setInfoEditing: action,
      setLongLoading: action,
      setRowLoading: action,
      setOrignalStateUpdateSelectedAppartment: action,
      setRemoveAppartment: action,
      setUpdateSelectedAppartment: action,
      updateCurrentNavTab: action,
      setRealLocation: action,
      setAddTagLink: action,
      setActionLink: action,
      toggleMenuMobile: action,
      closeMenuMobile: action,
      setSelectedClient: action,
      setAvatarMenu: action,
      setLoggedUser: action,
      setLoggedUserLang: action,
      setLoggedUserTwoFA: action,
      setLoggedToken: action,
      updateVendorEmail: action,
      updateVendorPassword: action,
      updateProjectName: action,
      updateProjectId: action,
      updateLoggedUser: action,
      setApartmentId: action,
      setSelectedAppartmentClient: action,
      setSelectedAppartment: action,
      setSelectedPertinenza: action,
      setPertinenzaId: action,
      setClientId: action,
      logOut: action,
      setAppartmentTypologies: action,
      setAssets: action,
      setAssetsByObject: action,
      updateClientToSave: action,
      updateClientToAdd: action,
      updateAppartmentToSave: action,
      setApartmentInformation: action,
      setErrorModal: action,
      setOpenTwoFAModal: action,
      setOpenNewsletterModal: action,
      setErrorMessage: action,
      setBreadCrumbs: action,
      setRemoveClientModal: action,
      setAppartmentModal: action,
      setLeadScoreInfoModal: action,
      setAddActionModal: action,
      setOpenTagHandler: action,
      setDeletingAction: action,
      setFeatureModal: action,
      setRemoveAppartmentModal: action,
      setRemoveFeatureModal: action,
      setConfirmProposalModal: action,
      setRefuseProposalModal: action,
      setAddInfoConfirmModal: action,
      setTabConfirmModal: action,
      setExpiredQuoteModal: action,
      setExpiredProposalModal: action,
      setRenewTimeQuoteModal: action,
      setDeleteQuoteModal: action,
      resetLastCreatedAction: action,
      setCreateAnotherAppointmentModal: action,
      updateGloballyClient: action,
      setCurrentEvent: action,
      setSystemLanguage: action,
      setDefaultLanguage: action,
      setOpenDrawer: action,
      setSetItemModal: action,
      setTabValueApartment: action,
      setCustomColumnsTableByProjectAndClient: action,
      getCustomColumnsTableByProjectAndClient: action,
      setTabValueClient: action,
      setAggiungiAccountModal: action,
      setSharePlanimetriaModal: action,
      setActiveMyHomeModal: action,
      setDeleteMyHomeModal: action,
      setDeactivateAccountModal: action,
      setStepperModal: action,
      setOpenCalendarSettings: action,
      setOpenFilterClientSettings: action,
      setErrorCalendarSettings: action,
      setUpdateAdditionalInfo: action,
      updatePertinenzaToSave: action,
      setCalendarSyncRevokeSuccess: action,
      setErrorUploadPlanimetryModal: action,
      setPaymentByClientModal: action,
      setRetryPaymentModal: action,
      setOpenDocumentModal: action,
      setOpenProposalModal: action,
      setProposalDocPath: action,
      setDocumentProposalToUpload: action,
      setQuote_ids: action,
      setSharedDocumentModal: action,
      setAppartments: action,
      setSnackbar: action,
      setSnackbarError: action,
      setSnackbarAmber: action,
      setClientsFromFilter: action,
      setProjectClients: action,
      setClientsToFilter: action,
      setCheckingJwt: action,
      setRemoveEvent: action,
      setUpdateEvent: action,
      setCancelEvent: action,
      setSearchAppartamenti: action,
      setSearchAsset: action,
      setSearchClienti: action,
      setSearchAppartamentiHome: action,
      setSearchClientiHome: action,
      setEventId: action,
      setAppartamentoSort: action,
      setAssetSort: action,
      setClienteSort: action,
      setClienteMyHomeSort: action,
      setClienteCRMSort: action,
      setCustomTableSort: action,
      setCalendarPopup: action,
      setClients: action,
      setAppointmentConfig: action,
      setPertinenze: action,
      setUsers: action,
      setUserCalled: action,
      setEvent: action,
      setEventList: action,
      setUpdEvent: action,
      setReservationTimeConfigModal: action,
      setValidateProposalModal: action,
      setSignProposalModal: action,
      setErrorImportApartmentModal: action,
      setRemoveFeature: action,
      setStatusProposal: action,
      setErrorImportApartment: action,
      setErrorUploadPlanimetry: action,
      setExpiredQuote: action,
      setExpiredProposal: action,
      setRenewTimeQuote: action,
      setDeleteQuote: action,
      forceLogout: action,
      setProposalToDelete: action,
      setDeleteProposalModal: action,
      setSaveInterestModal: action,
      setSaveErrorModal: action,
      setSaveModal: action,
      setClientsFromFilterDashboard: action,
      setClientsToFilterDashboard: action,
      setLoggedUserJwtData: action,
      setBaseUrl: action,
      setActionTimelineSelectedYear: action,
      setActionTimelineSelectedMonth: action,
      setOpenEnableModifyHCModal: action,
      setSelectedCustomization: action,
      setCalendarFilters: action,

      policyConfig: computed,
      initials: computed,
      clientInitials: computed,
      loggedUserFullname: computed,
      availableSizes: computed,
      selectedClientFullname: computed,
      selectedClientStatus: computed,
      clientToSaveIsValid: computed,
      appartmentToSaveIsValid: computed,
      clientToAddIsValid: computed,
      retrieveClientsListDashboard: computed,
      configLanguages: computed,
      enabledHomeConfigurator: computed,
      appartmentSelected: computed,
    });
    makePersistable(this, {
      name: 'store_followup',
      properties: [
        'breadCrumbs',
        'currentNavTab',
        'searchClienti',
        'searchAppartamenti',
        'searchAsset',
        'searchClientiHome',
        'appartamentoSort',
        'assetSort',
        'clienteSort',
        'clienteMyHomeSort',
        'clienteCRMSort',
        'tabValueClient',
        'tabValueApartment',
        'customTableSort',
        'customColumnsTable',
      ],
      storage: window.localStorage,
    });
  }

  /* ACTIONS */
  setBaseUrl(val) {
    this.baseUrl = val;
  }

  /**
   * updates tab.
   * @param {number} newTab the new selected tab (id)
   * @param {string} location
   */
  updateCurrentNavTab(newTab, location) {
    if (newTab !== this.currentNavTab) {
      this.currentNavTab = newTab;
    }
    this.setRealLocation(location);
  }

  /**
   * sets new location
   * @param {string} val
   */
  setRealLocation(val) {
    this.realLocation = val;
  }

  /**
   * set button add tag
   * @param {boolean} val
   */
  setAddTagLink(val) {
    this.addTagLink = val;
  }

  /**
   * set action link
   * @param {boolean} val
   */
  setActionLink(val) {
    this.actionLink = val;
  }

  setProjectClients(idProject, data, updatedAfter) {
    this.projectClients = { ...this.projectClients, [idProject]: { data, updatedAfter } };
  }

  setClientsFromFilter(val) {
    if (val) {
      // se ho un valore arrotondo alla mezzanotte per avere range temporale corretto (e non dipendente dall'ora di selezione del filtro)
      val = moment(new Date(val).setHours(0, 0, 0, 0));
    }

    this.clientsFromFilter = val;
  }

  setClientsFromFilterDashboard(val) {
    if (val) {
      // se ho un valore arrotondo alla mezzanotte per avere range temporale corretto (e non dipendente dall'ora di selezione del filtro)
      val = moment(new Date(val).setHours(0, 0, 0, 0));
    }

    this.clientsFromFilterDashboard = val;
  }

  setClientsToFilter(val) {
    if (val) {
      // se ho un valore arrotondo alla mezzanotte per avere range temporale corretto (e non dipendente dall'ora di selezione del filtro)
      val = moment(new Date(val).setHours(0, 0, 0, 0));
    }
    this.clientsToFilter = val;
  }

  setClientsToFilterDashboard(val) {
    if (val) {
      // se ho un valore arrotondo alla mezzanotte per avere range temporale corretto (e non dipendente dall'ora di selezione del filtro)
      val = moment(new Date(val).setHours(0, 0, 0, 0));
    }
    this.clientsToFilterDashboard = val;
  }

  /**
   * sets avatar's menu state (open/closed).
   * @param {boolean} val state of the menu
   */
  setAvatarMenu(val) {
    this.avatarMenu = val;
  }

  /**
   * sets modal shared document (open/closed).
   * @param {boolean} val state of the modal
   */
  setSharedDocumentModal(val) {
    this.sharedDocumentModal = val;
  }

  setLoadingClients(val) {
    this.loadingClients = val;
  }

  setCheckingJwt(val) {
    this.checkingJwt = val;
  }

  setLoggedUserJwtData(obj) {
    const { userData, checkingJwt } = obj;
    this.setLoggedUser(userData);
    this.setCheckingJwt(checkingJwt);
    if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
      datadogRum.setUser({
        id: userData.id,
        name: userData.firstName,
        surname: userData.lastName,
        email: userData.email,
        role: userData.role,
        TwoFA: userData.TwoFA,
      });
    }
  }

  /**
   * toggles mobile menu state (open/closed).
   */
  toggleMenuMobile() {
    this.menuMobile = !this.menuMobile;
  }

  /**
   * closes Menu Mobile drawer
   */
  closeMenuMobile() {
    this.menuMobile = false;
  }

  /**
   * sets the selected client
   * @param {Object} userData the selected client object
   */
  setSelectedClient(userData) {
    this.clientToSave = null;
    this.selectedClient = userData;
  }

  /**
   * sets the selected appartment
   * @param {Object} appartmentData the selected appartment object
   */
  setSelectedAppartment(appartmentData) {
    this.selectedAppartment = appartmentData;
  }

  /**
   * sets the selected appartment in client page
   * @param {Object} appartmentData the selected appartment object
   */
  setSelectedAppartmentClient(appartmentData) {
    this.selectedAppartmentClient = appartmentData;
  }

  /**
   * sets the logged user
   * @param {Object} userData the logged user object
   */
  setLoggedUser(userData) {
    if (!this.loggedUser || !isEqual(omit(this.loggedUser, ['language', 'TwoFA']), omit(userData, ['language', 'TwoFA']))) {
      this.loggedUser = userData;
    }
  }

  /**
   * sets the logged user language
   * @param {Object} userData the logged user object
   */
  setLoggedUserLang(language, locale) {
    if (language) {
      this.loggedUser.language = language;
    }
    if (locale) {
      this.loggedUser.locale = locale;
    }
  }

  getCurrentLanguage() {
    return this.loggedUser ? `${this.loggedUser?.language}-${this.loggedUser?.locale}` : undefined;
  }

  setLoggedUserTwoFA(value) {
    this.loggedUser.TwoFA = value;
  }

  /**
   * update logged user data
   * @param {Object} updatedData object containing updated data
   */
  updateLoggedUser(updatedData) {
    this.loggedUser = { ...this.loggedUser, ...updatedData };
  }

  /**
   * sets the logged token
   * @param {Object} tokenData the logged token object
   */
  setLoggedToken(tokenData) {
    if (!this.loggedToken) {
      this.loggedToken = tokenData;
    }
  }

  /**
   * sets the loging in vendor email
   * @param {string} val the email
   */
  updateVendorEmail(val) {
    if (this.vendorEmail !== val) {
      this.vendorEmail = val;
    }
  }

  /**
   * sets the loging in vendor password
   * @param {string} val the password
   */
  updateVendorPassword(val) {
    if (this.vendorPassword !== val) {
      this.vendorPassword = val;
    }
  }

  /**
   * sets the current project name
   * @param {string} pName project name
   */
  updateProjectName(pName) {
    if (this.projectName !== pName) {
      this.projectName = pName;
    }
  }

  /**
   * sets the current project id
   * @param {string} pId project id
   */
  updateProjectId(pId) {
    if (this.projectId !== pId) {
      this.projectId = pId;
    }
  }

  /**
   * sets the selected client id
   * @param {number} id the selected client id
   */
  setClientId(id) {
    this.clientId = id;
  }

  /**
   * sets the selected appartment id
   * @param {number} id the selected appartment id
   */
  setApartmentId(id) {
    this.apartmentId = id;
  }

  /**
   * sets the remove pop up
   */
  setRemoveEvent(value) {
    this.removeEvent = value;
  }
  /**
   * sets the remove pop up
   */
  setUpdateEvent(value) {
    this.updateEvent = value;
  }
  /**
   * sets the remove pop up
   */
  setCancelEvent(value) {
    this.cancelEvent = value;
  }

  /**
   * resets all persisted data
   */
  logOut() {
    this.loggedUser = null;
    this.projectId = null;
    this.breadCrumbs = null;
    this.currentNavTab = 0;
    this.appartamentoSort = [null, null, null, null, null, null, null, null];
    this.assetSort = [null, null, null, null, null];
    this.checkingJwt = false;
    this.baseUrl = undefined;
    this.clienteSort = [null, null, null, null, null, null, null];
    this.clienteMyHomeSort = [null, null, null, null, null];
    this.clienteCRMSort = [null, null, null, null, null, null, null];
    this.customTableSort = {};
    this.searchClienti = '';
    this.searchAppartamenti = '';
    this.searchAsset = '';
    this.searchClientiHome = '';
    this.tabValueClient = 0;
    this.tabValueApartment = 0;
    if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
      datadogRum.clearUser();
    }
  }

  /**
   *
   */
  setRowLoading(val) {
    if (this.rowLoading !== val) {
      this.rowLoading = val;
    }
  }

  /**
   *
   */
  setLongLoading(val) {
    if (this.longLoading !== val) {
      this.longLoading = val;
    }
  }

  /**
   *
   * @param {search terms} value
   */
  setSearchAppartamenti(value) {
    this.searchAppartamenti = value;
  }
  setSearchAsset(value) {
    this.searchAsset = value;
  }
  setSearchClienti(value) {
    this.searchClienti = value;
  }
  setSearchAppartamentiHome(value) {
    this.searchAppartamentiHome = value;
  }
  setSearchClientiHome(value) {
    this.searchClientiHome = value;
  }
  /**
   *
   */
  setEventId(id) {
    this.eventId = id;
  }
  /**
   *
   * @param {sort order} list
   */
  setAppartamentoSort(list) {
    this.appartamentoSort = list;
  }
  setAssetSort(list) {
    this.assetSort = list;
  }
  setClienteSort(list) {
    this.clienteSort = list;
  }
  setClienteMyHomeSort(list) {
    this.clienteMyHomeSort = list;
  }
  setClienteCRMSort(list) {
    this.clienteCRMSort = list;
  }
  setCustomTableSort(list) {
    this.customTableSort = list;
  }

  setCalendarPopup(val) {
    if (this.calendarPopup !== val) {
      this.calendarPopup = val;
    }
  }
  /**
   * sets global editing on a form
   * @param {bool} val
   */
  setGlobalEditing(val) {
    this.globalEditing = val;
  }

  /**
   * sets global editing on a form
   * @param {bool} val
   */
  setInfoEditing(val) {
    this.infoEditing = val;
  }

  /**
   * TODO: this method should be removed in a future release, as assets
   * and projectName should come from BE
   * @param {string} assetKey the key to choose fe assets (i.e. images)
   * @param {string} projectName the projectName to use in BE fetches
   * @param {string} displayName the name to display at login
   * @param {string} assetUrl the URL to retrieve images
   */
  setAssets(assetKey, projectName, projectId, displayName, assetsUrl) {
    this.feKey = assetKey;
    this.projectName = projectName;
    this.projectId = projectId;
    this.loginProjectName = displayName;
    this.assetsUrl = assetsUrl;
  }

  /**
   * TODO: this method should be removed in a future release, as assets
   * and projectName should come from BE
   * @param {object} obj an object containing all assets
   */
  setAssetsByObject(obj) {
    if (obj) {
      this.feKey = obj.assetKey;
      this.assetLogin = obj.assetLogin;
      this.logo = obj.logo;
      this.externalPdf = obj.pdf;
      this.projectName = obj.name;
      this.projectId = obj.id;
      this.loginProjectName = obj.displayName;
      this.hostKey = obj.hostKey;
      this.assetsUrl = obj.assetsUrl;
      this.feVendorKey = obj.feVendorKey;
      this.project_id = obj.id;
      this.vendorLogo = obj.vendorLogo;
      this.pageTitles = obj.pageTitles;
      this.enabledSections = obj.followupConfig ? obj.followupConfig.enabledSections : [];
      this.languages = obj.followupConfig ? obj.followupConfig.languages : undefined;
      this.projectLanguages = obj.languages;
      this.dashboardConfig = obj.followupConfig ? obj.followupConfig.dashboardConfig : undefined;
      this.ciroIntegrationEnabled = obj.ciroIntegrationEnabled;
      this.planInfo = obj.followupConfig ? obj.followupConfig.planInfo : undefined;
      this.enabledTools = obj.enabledTools;
      this.locale = obj.locale;
      this.currency = obj.currency;
      this.enabledStatus = obj.followupConfig && obj.followupConfig.enabledStatus ? obj.followupConfig.enabledStatus : null;
      this.myhomeConfig = obj.myhomeConfig;
      this.myhomeProDisabled = obj.myhomeProDisabled;
      this.policyConfigObj = obj.policyFlags;
      this.homeConfiguratorConfig = obj.homeConfiguratorConfig;
      this.automaticQuoteEnabled = obj.automaticQuoteEnabled;
      this.installment = obj.floorPlanningConfig && obj.floorPlanningConfig.installment ? obj.floorPlanningConfig.installment : {};
      this.daysBeforeExpiryAutomaticReservation = obj.daysBeforeExpiryAutomaticReservation;
      this.storeAddress = obj.storeAddress;
      this.projectFlags = obj.projectFlags;
      this.defaultLang = obj.defaultLang;
      this.baseUrl = obj.baseurl;
    }
  }

  /**
   * set appartment typologies
   * @param {array} data an array of typologies
   */
  setAppartmentTypologies(data) {
    this.typologies = data;
  }

  /**
   * set clients
   * @param {array} data an array of clients
   */
  setClients(data) {
    this.clients = data;
  }

  setAppointmentConfig(data) {
    this.appointmentConfig = data;
  }

  /**
   * set appartments
   * @param {array} data an array of appartments
   */
  setAppartments(data) {
    this.appartments = data;
  }

  /**
   * set pertinenze
   * @param {array} data an array of appartments
   */
  setPertinenze(data) {
    this.pertinenze = data;
  }

  /**
   * set selectedPertinenza
   * @param {object} data an object of pertinenza
   */
  setSelectedPertinenza(data) {
    this.selectedPertinenza = data;
  }

  /**
   * set pertinenzaId
   * @param {string} data an id of pertinenza
   */
  setPertinenzaId(data) {
    this.pertinenzaId = data;
  }

  /**
   * set users
   * @param {array} data an array of users
   */
  setUsers(data) {
    this.users = data;
  }

  /**
   * set userCalled
   * @param data user called to modify
   */
  setUserCalled(data) {
    this.userCalled = data;
  }

  /**
   * set calendar event
   * @param {array} array of events
   *  */
  setEvent(data) {
    this.event = data;
  }

  setEventList(data) {
    this.eventList = data;
  }

  /**
   * set calendar creation event
   * @param {dictionary} single event
   *  */
  setCurrentEvent(data) {
    this.currentEvent['vendorId'] = this.loggedUser.id;
    this.currentEvent['projectId'] = this.feKey;
    this.currentEvent = data;
  }
  /**
   * set calendar creation event
   * @param {dictionary} single event
   *  */
  setUpdEvent(data) {
    this.updEvent = data;
  }

  /**
   * open/close lateral navbar
   * @param {boolean} val
   */
  setOpenDrawer(val) {
    this.openDrawer = val;
  }

  /**
   * open/close calendar settings modal
   * @param {boolean} val
   */
  setOpenCalendarSettings(val) {
    this.calendarSettingsModal = val;
  }

  /**
   * open/close filter client settings modal
   * @param {boolean} val
   */
  setOpenFilterClientSettings(val) {
    this.filterClientSettingsModal = val;
  }

  setErrorCalendarSettings(val) {
    this.errorCalendarSettings = val;
  }
  setUpdateAdditionalInfo(val) {
    this.updateAdditionalInfo = val;
  }

  /**
   * open/close stepper modal
   * @param {boolean} val
   */
  setStepperModal(val) {
    this.stepperModal = val;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setSetItemModal(val) {
    this.setItemModal = val;
  }

  /**
   * tab open in apartment
   * @param {number} val
   */
  setTabValueApartment(val) {
    this.tabValueApartment = val;
  }

  /**
   * set custom columns
   * @param {array} val
   */
  setCustomColumnsTableByProjectAndClient(val) {
    this.customColumnsTable = {
      ...this?.customColumnsTable,
      [this?.project_id]: { ...(this?.customColumnsTable?.[this.project_id] ?? {}), [this.loggedUser?.id]: val },
    };
    setCustomColumnsTable(this.project_id, this.loggedUser?.id, val);
  }

  /**
   * get custom columns
   */
  getCustomColumnsTableByProjectAndClient() {
    return getCustomColumnsTable(this.projectId, this.loggedUser?.id);
  }

  /**
   * tab open in clients manager
   * @param {number} val
   */
  setTabValueClient(val) {
    this.tabValueClient = val;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setAggiungiAccountModal(val) {
    this.openAccountModal = val;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setSharePlanimetriaModal(val) {
    this.openSharePlanimetriaModal = val;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setReservationTimeConfigModal(val) {
    this.openReserveTimeModal = val;
  }

  /**
   * open/close active my home modal
   * @param {boolean} val
   */
  setActiveMyHomeModal(val) {
    this.activeMyHomeModal = val;
  }

  /**
   * open/close active payment by Client
   * @param {boolean} val
   */
  setPaymentByClientModal(val) {
    this.paymentByClientModal = val;
  }

  /**
   * open/close retry payment Modal
   * @param {boolean} val
   */

  setRetryPaymentModal(val) {
    this.retryPaymentModal = val;
  }

  setProposalToDelete(val) {
    this.proposalToDelete = val;
  }

  /**
   * open/close delete my home modal
   * @param {boolean} val
   */
  setDeleteMyHomeModal(val) {
    this.deleteMyHomeModal = val;
  }

  /**
   * open/close setItem modal
   * @param {boolean} val
   */
  setDeactivateAccountModal(val) {
    this.deactivateAccountModal = val;
  }

  /**
   * open/close error modal
   * @param {boolean} val
   */
  setErrorModal(val) {
    this.errorModal = val;
  }

  setOpenTwoFAModal(val) {
    this.openTwoFAModal = val;
  }

  setOpenNewsletterModal(val) {
    this.openNewsletterModal = val;
  }

  setSaveModal(val) {
    this.saveModal = val;
  }
  setSaveErrorModal(val) {
    this.saveErrorModal = val;
  }
  setSaveInterestModal(val) {
    this.saveInterestModal = val;
  }
  setErrorMessage(val) {
    this.errorMessage = val;
  }

  setUploadBulkLoader(v) {
    this.uploadBulkLoader = v;
  }

  setBulkDone(v1, v2) {
    this.bulkDone = v1;
    this.bulkError = v2 || false;
  }

  /**
   * updates client to save
   * @param {object} data updated client
   */
  updateClientToSave(data) {
    this.clientToSave = data;
  }

  /**
   * updates client to add
   * @param {object} data client to add
   */
  updateClientToAdd(data) {
    this.clientToAdd = data;
  }

  /**
   * updates appartment to save
   * @param {object} data updated client
   */
  updateAppartmentToSave(data) {
    this.appartmentToSave = data;
  }

  /**
   * updates appartment to save
   * @param {object} data updated client
   */
  setApartmentInformation(data) {
    this.apartmentInformation = data;
  }

  /**
   * updates pertinenza to save
   * @param {object} data updated pertinenza
   */
  updatePertinenzaToSave(data) {
    this.pertinenzaToSave = data;
  }

  /**
   * sets breadcrumbs
   * @param {string} bread parent bread crumb
   */
  setBreadCrumbs(bread) {
    this.breadCrumbs = bread;
  }

  /**
   * set remove client modal open/close
   * @param {boolean} val  clientmodal
   */
  setRemoveClientModal(val) {
    this.removeClientModal = val;
  }

  /**
   * set delete proposal modal open/close
   * @param {boolean} val  proposal modal
   */
  setDeleteProposalModal(val) {
    this.deleteProposalModal = val;
  }

  setLeadScoreInfoModal(val, openClient) {
    this.openClient = openClient;
    this.leadScoreInfoModal = val;
  }

  /**
   * set appartment modal open/close
   * @param {boolean} val  appartmentmodal
   * @param {boolean} val2 adding appartments
   */
  setAppartmentModal(val, val2) {
    this.appartmentModal = val;
    this.addingAppartments = val2;
  }
  /**
   * set action modal open/close
   * @param {boolean} val  appartmentmodal
   */
  setAddActionModal(val, action) {
    this.updatingAction = action;
    this.addActionModal = val;
  }
  /**
   * set tag modal open/close
   */
  setOpenTagHandler(val) {
    this.openTagHandler = val;
  }
  /**
   * open close deleting action modal
   */
  setDeletingAction(val) {
    this.deletingAction = val;
  }
  /**
   * set feature modal open/close
   * @param {boolean} val  featuremodal
   */
  setFeatureModal(val) {
    this.featureModal = val;
  }

  /**
   * set remove appartment modal open/close
   * @param {boolean} val  appartmentmodal
   */
  setRemoveAppartmentModal(val) {
    this.removeAppartmentModal = val;
  }

  /**
   * set remove feature modal open/close
   * @param {boolean} val  featuremodal
   */
  setRemoveFeatureModal(val) {
    this.removeFeatureModal = val;
  }

  /**
   * set confirm proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setConfirmProposalModal(val) {
    this.confirmProposalModal = val;
  }

  /**
   * set validate proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setValidateProposalModal(val) {
    this.validateProposalModal = val;
  }

  /**
   * set sign proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setSignProposalModal(val) {
    this.signProposalModal = val;
  }

  /**
   * set refuse proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setRefuseProposalModal(val) {
    this.refuseProposalModal = val;
  }

  /**
   * set client info modal open/close
   * @param {boolean} val  clientInfoModal
   */
  setAddInfoConfirmModal(val) {
    this.addInfoConfirmModal = val;
  }
  /**
   * set client info modal change tab
   * @param {boolean} val  clientInfoModal
   */
  setTabConfirmModal(val) {
    this.tabConfirmModal = val;
  }

  /**
   * set refuse proposal modal open/close
   * @param {boolean} val  proposalmodal
   */
  setErrorImportApartmentModal(val) {
    this.errorImportApartmentModal = val;
  }

  setErrorUploadPlanimetryModal(val) {
    this.errorUploadPlanimetryModal = val;
  }

  /**
   * set modal to renew expired quote open/close
   * @param {boolean} val
   */
  setExpiredQuoteModal(val) {
    this.expiredQuoteModal = val;
  }
  /**
   * set modal to renew expired proposal open/close
   * @param {boolean} val
   */
  setExpiredProposalModal(val) {
    this.expiredProposalModal = val;
  }

  /**
   * set modal to renew expired reservation time open/close
   * @param {boolean} val
   */
  setRenewTimeQuoteModal(val) {
    this.renewTimeQuoteModal = val;
  }

  /**
   * set modal to delete quote open/close
   * @param {boolean} val
   */
  setDeleteQuoteModal(val) {
    this.deleteQuoteModal = val;
  }

  /**
   * reset lastCreatedAction value
   */
  resetLastCreatedAction() {
    this.lastCreatedAction = {};
  }

  /**
   * set modal to create new appointment open/close
   * @param {boolean} val
   * @param {object} createdAction
   */
  setCreateAnotherAppointmentModal(val, createdAction) {
    this.createAnotherAppointmentModal = val;
    if (val && createdAction) {
      this.lastCreatedAction['vendorId'] = this.loggedUser.id;
      this.lastCreatedAction['projectId'] = this.feKey;
      this.lastCreatedAction = { ...this.lastCreatedAction, ...createdAction };
    }
  }

  /**
   * set modal to select typology's document open/close
   * @param {boolean} val
   */
  setOpenDocumentModal(val) {
    this.openDocumentModal = val;
  }

  /**
   * set modal to upload proposal's document open/close
   * @param {boolean} val
   */
  setOpenProposalModal(val) {
    this.openProposalModal = val;
  }

  /**
   * set proposal's path document to upload
   * @param {string} val
   */
  setProposalDocPath(val) {
    this.proposalDocPath = val;
  }

  /**
   * set proposal's document to upload
   * @param {Object} val
   */
  setDocumentProposalToUpload(val) {
    this.documentProposalToUpload = val;
  }

  setQuote_ids(val) {
    this.quote_ids = val;
  }

  /**
   * sets currently modifying appartment
   * @param {object} app
   */
  setUpdateSelectedAppartment(app) {
    if (!app || Object.keys(app ?? {}).length === 0) {
      this.modifyingAppartment = {
        id: '',
        name: '',
        status: '',
        plan: { quadrants: [] },
        availableQuadrants: [],
      };
    } else {
      this.modifyingAppartment = app;
    }
  }

  /**
   * sets currently modifying appartment original status
   * @param {string} state
   */
  setOrignalStateUpdateSelectedAppartment(state) {
    this.modifyingAppartmentOriginalState = state;
  }

  /**
   * set appartment to remove
   * @param {object} val
   */
  setRemoveAppartment(val) {
    this.removeAppartment = val;
  }

  /**
   * set feature to remove
   * @param {object} val
   */
  setRemoveFeature(val) {
    this.removeFeature = val;
  }

  /**
   *
   * @param {object} v1 clientToSave
   * @param {object} v2 selecteClient
   * @param {func} callback
   */
  updateGloballyClient(v1, v2, callback) {
    this.clientToSave = v1;
    this.selectedClient = v2;
    if (callback) {
      callback();
    }
  }

  /**
   * sets snackbar open/closed
   * @param {boolean} val
   * @param {string} mes message to display
   */
  setSnackbar(val, mes) {
    if (val !== this.snackbarOpen) {
      this.snackbarMessage = mes;
      this.snackbarOpen = val;
    }
  }

  /**
   * sets snackbar amber open/closed
   * @param {boolean} val
   * @param {string} mes message to display
   */
  setSnackbarAmber(val, mes) {
    if (val !== this.snackbarAmberOpen) {
      this.snackbarMessage = mes;
      this.snackbarAmberOpen = val;
    }
  }

  /**
   * sets snackbar error open/closed
   * @param {boolean} err
   * @param {string} mes message to display
   */
  setSnackbarError(err, mes) {
    if (err !== this.snackbarError) {
      this.snackbarMessage = mes;
      this.snackbarError = err;
    }
  }

  /**
   * sets pdf to view in pdf viewer
   * @param {string} val
   */
  setPdfToView(val) {
    this.pdfToView = val;
  }

  /**
   * set proposal to validate
   * @param {object} val
   */
  setStatusProposal(val) {
    this.statusProposal = val;
  }

  /**
   * set proposal to refuse
   * @param {object} val
   */
  setErrorImportApartment(val) {
    this.errorImportApartment = val;
  }

  setErrorUploadPlanimetry(val) {
    this.errorUploadPlanimetry = val;
  }

  /**
   * set expired quote
   * @param {object} val
   */
  setExpiredQuote(val) {
    this.expiredQuote = val;
  }

  /**
   * set expired quote
   * @param {object} val
   */
  setExpiredProposal(val) {
    this.expiredProposal = val;
  }

  /**
   * set expiration reservation time
   * @param {object} val
   */
  setRenewTimeQuote(val) {
    this.renewTimeQuote = val;
  }

  /**
   * set delete quote
   * @param {object} val
   */
  setDeleteQuote(val) {
    this.deleteQuote = val;
  }

  setUploadBulkDataModal(v) {
    this.uploadBulkDataModal = v;
  }

  /**
   * set the language of the system
   * @param {string} val
   */
  setSystemLanguage(val) {
    this.systemLanguage = val;
  }
  setDefaultLanguage(val) {
    this.defaultLanguage = val;
  }

  /**
   * set calendar sync revoke success
   * @param {string} status
   */
  setCalendarSyncRevokeSuccess(status) {
    this.calendarSyncRevokeSuccess = status;
  }

  forceLogout(logout) {
    this.redirectionToSsoNeeded = logout;
  }

  setActionTimelineSelectedYear(selectedYear) {
    this.actionTimelineSelectedYear = selectedYear;
  }

  setActionTimelineSelectedMonth(selectedMonth) {
    this.actionTimelineSelectedMonth = selectedMonth;
  }

  setOpenEnableModifyHCModal(openEnableModifyHCModal) {
    this.openEnableModifyHCModal = openEnableModifyHCModal;
  }

  setSelectedCustomization(selectedCustomization) {
    this.selectedCustomization = selectedCustomization;
  }

  setCalendarFilters(filters) {
    this.calendarFilters = filters;
  }

  /* COMPUTED */

  get policyConfig() {
    if (this.policyConfigObj) {
      const currLanguage = this.systemLanguage;
      const defPolicyLanguage = Object.keys(this.policyConfigObj)[0];
      return this.policyConfigObj[alpha2ToAlpha3T(currLanguage)] || this.policyConfigObj[defPolicyLanguage] || [];
    }
    return [];
  }

  /**
   * computes the initials of the currently logged user
   */
  get initials() {
    if (this.loggedUser && this.loggedUser.firstName && this.loggedUser.lastName) {
      return Initials(this.loggedUser.firstName, this.loggedUser.lastName);
    } else {
      return '-';
    }
  }

  /**
   * computes the logged user full name
   */
  get loggedUserFullname() {
    if (this.loggedUser && this.loggedUser.firstName && this.loggedUser.lastName) {
      return `${this.loggedUser.firstName} ${this.loggedUser.lastName}`;
    } else if (this.loggedUser && this.loggedUser.id) {
      return this.loggedUser.id;
    } else {
      return '-';
    }
  }

  /**
   * computes an array of names
   */
  get availableSizes() {
    return this.typologies.map((e) => Capitalize(e.name));
  }

  /**
   * computes the selected client initials
   */
  get clientInitials() {
    if (this.selectedClient && this.selectedClient.firstName && this.selectedClient.lastName) {
      return Initials(this.selectedClient.firstName, this.selectedClient.lastName);
    } else {
      return '-';
    }
  }

  /**
   * computes the selected client full name
   */
  get selectedClientFullname() {
    if (this.selectedClient && this.selectedClient.firstName && this.selectedClient.lastName) {
      return `${this.selectedClient.firstName} ${this.selectedClient.lastName}`;
    } else if (this.selectedClient && this.selectedClient.id) {
      return this.selectedClient.id;
    } else {
      return '-';
    }
  }

  /**
   * computes the selected client status
   */
  get selectedClientStatus() {
    return this.selectedClient && this.selectedClient.status ? this.selectedClient.status : null;
  }

  /**
   * extra validation for the client
   */
  get clientToSaveIsValid() {
    return this.clientToSave && ValidateEmail(this.clientToSave.email) && ValidatePhone(this.clientToSave.tel);
  }

  /**
   * extra validation for the appartment
   */
  get appartmentToSaveIsValid() {
    return true;
    // return ValidateEmail(this.appartmentToSave.selectedBy);
  }

  /**
   * extra validation for the added client
   */
  get clientToAddIsValid() {
    return (
      this.clientToAdd &&
      !!this.clientToAdd.firstName &&
      !!this.clientToAdd.lastName &&
      /*this.clientToAdd.trattamento &&*/ ValidateEmail(this.clientToAdd.email) &&
      ValidatePhone(this.clientToAdd.tel)
    );
  }

  get retrieveClientsList() {
    if (this.clients !== undefined) {
      // gestione date
      let clientsCopy = [...this.clients];

      clientsCopy = clientsCopy.filter((client) => {
        const createdOnDate = new Date(client.createdOn).setHours(0, 0, 0, 0);
        let greaterThan = true;
        let lessThan = true;
        if (this.clientsFromFilter) {
          greaterThan = moment(this.clientsFromFilter).diff(moment(createdOnDate)) <= 0;
        }
        if (this.clientsToFilter) {
          lessThan = moment(this.clientsToFilter).diff(moment(createdOnDate)) >= 0;
        }
        return greaterThan && lessThan;
      });
      return clientsCopy;
    } else return [];
  }

  get retrieveClientsListDashboard() {
    if (this.clients !== undefined) {
      // gestione date
      let clientsCopy = [...this.clients];

      clientsCopy = clientsCopy.filter((client) => {
        const createdOnDate = new Date(client.createdOn).setHours(0, 0, 0, 0);
        let greaterThan = true;
        let lessThan = true;
        if (this.clientsFromFilterDashboard) {
          greaterThan = moment(this.clientsFromFilterDashboard).diff(moment(createdOnDate)) <= 0;
        }
        if (this.clientsToFilterDashboard) {
          lessThan = moment(this.clientsToFilterDashboard).diff(moment(createdOnDate)) >= 0;
        }
        return greaterThan && lessThan;
      });
      return clientsCopy;
    } else return [];
  }

  get configLanguages() {
    if (Array.isArray(this.languages)) {
      // SERVONO VERAMENTE QUESTE CONVERSIONI?
      return this.languages.map((lang) => {
        return convertLanguage(lang);
      });
    }
    return ['it-IT', 'en-GB'];
  }

  get enabledHomeConfigurator() {
    return this.homeConfiguratorConfig && Object.keys(this.homeConfiguratorConfig).length > 0;
  }

  /**
   * computes the selected appartment
   */
  get appartmentSelected() {
    return this.appartments.find((apt) => apt.id === this.modifyingAppartment.id);
  }
}

export default Store;
