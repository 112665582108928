import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import 'styles/card.scss';

/**
 * Default card
 * based on material-ui Card
 * Unknown props are passed down to the MUI underlying component
 * @component
 * @see {@link https://material-ui.com/api/card/ Card API}
 */
const CrmCard = (props) => {
  const { children, extraClasses, extraClassesTab, ...rest } = props;

  return (
    <Card raised className={`card-wrapper ${extraClasses}`} {...rest}>
      {children}
    </Card>
  );
};

CrmCard.defaultProps = {
  extraClasses: '',
};

/** prop types */
CrmCard.propTypes = {
  /** extraClass: additional className */
  extraClasses: PropTypes.string,
  /** children: required card content */
  children: PropTypes.node.isRequired,
};

export default CrmCard;
