import moment from 'moment';
import { useIsProperty } from './useIsProperty';
import { useStore } from 'store/storeUtils';
import { useVendor } from './useVendor';
import { useHistory } from 'react-router-dom';

export const useCalendar = () => {
  const isProperty = useIsProperty();
  const store = useStore();
  const vendor = useVendor();
  const history = useHistory();

  const onEventCreate = () => {
    const data = {
      start: moment(),
      extendedProps: {},
      view: { type: '' },
    };

    if (!isProperty) {
      const day = moment(data.start, 'YYYY-MM-DD');
      const now = moment(data.start);
      let m = now.minutes();
      const minutes = ((Math.floor(m / 15) + 1) * 15) % 60;
      let h = now.hour();
      const hours = m >= 45 ? (h === 23 ? 0 : ++h) : h;

      data['data'] = {
        color: data.color,
        id: data.id,
        start: now.set({ minute: minutes, hour: hours }),
        end: moment(data.start).set({ minute: minutes, hour: hours }).add(1, 'hours'),
        day: day,
        title: data.title,
        // client: '',
        // phone: '',
        // email: '',
        // memo: '',
        place: store.storeAddress,
        vendor,
      };

      store.setCurrentEvent(data);
      // store.setBreadCrumbs('title');
      store.setRealLocation('/new-event/undefined');
      history.push('/new-event/undefined');
    } else console.warn('You are not allowed to create events');
  };

  return { onEventCreate };
};
