export const defaultSort = (a = '', b = '', order) => {
  if (order === 'asc') {
    if (a === '' || a === null) return 1;
    if (b === '' || b === null) return -1;
  }
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export default {
  defaultSort,
};
