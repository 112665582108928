import { useProjectClients } from './useProjectClients';

export const useClientsAll = () => {
  const clients = useProjectClients();

  return {
    clients: {
      data: {
        getAllClientsFollowUp: clients || [],
      },
      loading: clients.length === 0,
      networkStatus: 7,
    },
  };
};
