import { memo } from 'react';
import { useLoggedUser } from 'store/hooks/useLoggedUser';
import { useTodayDate } from 'utils/getTodayDate';
import './Header.css';
import { useTranslation } from '@tecma/i18n';

export const Header = memo(() => {
  const { firstName, lastName } = useLoggedUser();
  const { day, dateNumber, month } = useTodayDate();
  const { t } = useTranslation();

  return (
    <section className='responsive-section'>
      <div
        className='responsive-image'
        style={{
          backgroundImage: `url(${process.env.REACT_APP_BUCKET_BASEURL}/global/img/tools/followup.jpeg?v=1)`,
        }}
      />
      <div className='responsive-content'>
        <h1>{t('home.welcome.title', { firstName, lastName })}</h1>
        <div className='section-subtitle'>{t('home.welcome.description')}</div>
        <div className='section-date'>
          <div className='section-day'>{day}</div>
          <div className='section-month'>{month}</div>
          <div className='section-number'>{dateNumber}</div>
        </div>
      </div>
    </section>
  );
});
