import React from 'react';
import CrmSnackbar from 'components/common/CrmSnackbar';
import { useStore } from 'store/storeUtils.js';
import { observer } from 'mobx-react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const CrmSnackbarAmber = observer(() => {
  const store = useStore();
  return (
    <CrmSnackbar open={store.snackbarAmberOpen} onClose={() => store.setSnackbarAmber(false)}>
      <div className='snackbar-amber-body'>
        <InfoOutlinedIcon style={{ marginRight: '5px' }} />
        {store.snackbarMessage}
      </div>
    </CrmSnackbar>
  );
});

export default CrmSnackbarAmber;
