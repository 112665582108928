import React, { memo, useState } from 'react';
import srcImage from '../../../images/assets/home/desktop-blur.jpg';
import Dashboard from 'pages/Dashboard';
import { useTranslation } from '@tecma/i18n';

export const PlaceholderDashboard = memo(() => {
  const [showDashboard, setShowDashboard] = useState(false);
  const { t } = useTranslation();

  const onClickShowDashboard = () => {
    setShowDashboard(true);
  };

  if (showDashboard)
    return (
      <div style={{ position: 'relative', marginTop: 100 }}>
        <Dashboard />
      </div>
    );

  return (
    <div style={{ height: '100%', width: '100%', marginTop: 50, position: 'relative' }}>
      <img src={srcImage} alt='Dashboard Placeholder' style={{ width: '100%' }} />
      <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <p>{t('home.dashboard.title')}</p>
          <button onClick={onClickShowDashboard} type='button' style={{ backgroundColor: '#585BD7', color: '#FFFFFF', border: 'none', borderRadius: 4, padding: '12px 24px', cursor: 'pointer' }}>
            {t('home.dashboard.button')}
          </button>
        </div>
      </div>
    </div>
  );
});
