import React from 'react';
import ChartConstants from 'constants/ChartConstants';
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import Capitalize from 'utils/Capitalize';
import 'styles/charts.scss';
import { useStore } from 'store/storeUtils';
import { percFormat } from 'utils/formatting';
import { useTranslation } from '@tecma/i18n';

/**
 * DoughnutChart
 * based on recharts
 *
 * @component
 * @see {@link http://recharts.org/en-US/api/PieChart PieChart API}
 */
const DoughnutChart = (props) => {
  const { availableStatuses, data, height, width, innerRadius, outerRadius, className } = props;
  const store = useStore();
  const { t } = useTranslation();

  let total = 0;
  let max = 0;
  if (data) {
    data.forEach((el) => {
      if (el.data) {
        total += el.data;
        max = Math.max(max, el.data);
      }
    });
  }

  const formatter = (value, maxValue, name) => {
    return [percFormat(value, maxValue), Capitalize(availableStatuses[name])];
  };

  const CustomTooltip = (dataTooltip) => {
    if (dataTooltip.active) {
      let formatArray = formatter(dataTooltip.payload[0].payload.data, total, dataTooltip.payload[0].name);
      let label = dataTooltip.payload[0].payload.label;
      return (
        <div className='recharts-tooltip-wrapper'>
          <div className='recharts-default-tooltip custom-tooltip'>
            <p className='recharts-tooltip-label'>{`${t(`dashboard.${label}`, {
              defaultValue: label,
            })} : ${CurrencyFormatter(store.currency, dataTooltip.payload[0].payload.data)} - ${formatArray[0]}`}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width='100%' height={height} className={`doughnut-chart-container ${className}`}>
      <PieChart width={width} height={height} className='donut-chart'>
        <Pie cx='50%' cy='50%' data={data} innerRadius={innerRadius} outerRadius={outerRadius} dataKey='data'>
          {data.map((entry, index) => {
            return <Cell dataKey='data' key={`cell-${index}`} fill={entry.color} />;
          })}
        </Pie>
        <Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: () => true, y: () => true }} wrapperStyle={{ top: -30, left: 5 }} />
      </PieChart>
    </ResponsiveContainer>
  );
};

DoughnutChart.propTypes = {
  /**
   * availableStatuses: all statuses to be displayed on chart and legend
   */
  availableStatuses: PropTypes.arrayOf(PropTypes.string),
  /**
   * availableColors: colors to use for each status
   */
  availableColors: PropTypes.arrayOf(PropTypes.string),
  /**
   * data
   */
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * width: of the chart
   */
  width: PropTypes.number,
  /**
   * height: of all the elements
   */
  height: PropTypes.number,
  /**
   * innerRadius: 0 is a pie chart. The higher it is, the bigger the "hole" at the center of the chart will be
   */
  innerRadius: PropTypes.number,
  /**
   * outerRadius: how "big" the chart is
   */
  outerRadius: PropTypes.number,
  /**
   * className: component class name
   */
  className: PropTypes.string,
};

DoughnutChart.defaultProps = {
  availableStatuses: ChartConstants.availableStatus,
  availableColors: ChartConstants.defaultColors,
  data: [],
  width: 500,
  height: 200,
  innerRadius: 40,
  outerRadius: 80,
  className: '',
};

export default DoughnutChart;
