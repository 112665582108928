import React from 'react';
import ChartConstants from 'constants/ChartConstants';
import PropTypes from 'prop-types';
import { useTranslation } from '@tecma/i18n';
import { useStore } from 'store/storeUtils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';
import ChartTitle from 'components/charts/ChartTitle';
import DoughnutReChart from 'components/charts/DoughnutReChart';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import { percFormat } from 'utils/formatting';
import NoDataChartDashboard from './NoDataChartDashboard';

import 'styles/charts.scss';
import 'styles/DoughnutChartDashboardWrapper.scss';

const DoughnutChartDashboardWrapper = (props) => {
  const { title, className, availableStatuses, availableColors, data, height, width, innerRadius, outerRadius, showPromoLegend, focusFunction, type, onFocus, clickableLegend } = props;

  const { t } = useTranslation();
  const store = useStore();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const matchesXLgNonClickable = useMediaQuery('(min-width:1450px)', {
    noSsr: true,
  });

  const matchesXLgClickable = useMediaQuery('(min-width:1280px)', {
    noSsr: true,
  });

  const [clicked, setClicked] = React.useState(false);
  let total = 0;
  let max = 0;
  if (data) {
    data.forEach((el) => {
      if (el.data) {
        total += el.data;
        max = Math.max(max, el.data);
      }
    });
  }
  let findMaxLen = (arr) => {
    let max = 0;
    for (let i = 0; i <= arr.length - 1; i += 1) {
      if (i === arr.length - 1) {
        return max.toFixed(0).length;
      } else {
        max > arr[i] ? max : (max = arr[i]);
      }
    }
    return max.toFixed(0).length;
  };
  const maxLen = findMaxLen(data.map((item) => item.data));

  const NonClickableLegend = ({ className }) => {
    return (
      <div className={className}>
        {data.map((status, index) => {
          const iconColor = status.color;
          const money = status.data;
          const label = status.label;
          const quantity = status.total;
          const promo = status.promo;

          return (
            <div key={index} style={index === status.length - 1 ? {} : { marginBottom: '10px' }} className={!matches ? 'font-16 ' : 'small-font-14 '}>
              <div className='flex-centered-between legend-item'>
                <div className='flex-centered'>
                  <div className={'donut-legend-icon'} style={{ backgroundColor: iconColor }} />
                  <Tooltip
                    title={t(`dashboard.${label}`, {
                      defaultValue: label,
                    })}
                  >
                    <span style={!matchesPhone ? { marginRight: '25px' } : {}} data-testid='donut-legend-label'>
                      {t(`dashboard.${label}`, {
                        defaultValue: label,
                      })}
                    </span>
                  </Tooltip>
                </div>
                <div key={`item-${index}`} className='flex'>
                  <div className='flex'>
                    {!isNaN(quantity) ? (
                      <span data-testid='feature' className='semi-bold doughnut-dash-wrapper-legend-data-with-separator' style={{ minWidth: '25px' }}>
                        {quantity}
                      </span>
                    ) : (
                      <span></span>
                    )}
                    <span data-testid='feature-perc' className='doughnut-dash-wrapper-legend-data-with-separator' style={{ minWidth: matchesPhone ? '45px' : '50px' }}>
                      {`(${percFormat(status.data, total)})`}
                    </span>
                    <span data-testid='feature-value' className='medium' style={{ minWidth: `${(matchesPhone ? 15 : 16) * maxLen}px`, textAlign: 'end' }}>
                      {CurrencyFormatter(store.currency, money)}
                    </span>
                  </div>
                </div>
              </div>
              {!!promo && showPromoLegend && (
                <div className='doughnut-dash-wrapper-legend-promo-container'>
                  <span style={{ marginLeft: '26px' }}>{t('dashboard.promo')}</span>
                  <span className='flex-container-end' style={!matchesPhone ? (!isNaN(quantity) ? { minWidth: '220px' } : { minWidth: '193px' }) : { minWidth: '160px' }}>
                    {CurrencyFormatter(store.currency, promo)}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const ClickableLegend = ({ className }) => {
    return (
      <div className={className}>
        {data.map((status, index) => {
          const iconColor = status.color;
          const money = status.data;
          const label = status.label;
          const quantity = status.total;
          const promo = status.promo;
          const currentlySelected = onFocus === label + '_' + type;
          const openAndSelected = clicked && currentlySelected;
          return (
            <div
              key={index}
              style={index === status.length - 1 ? {} : { marginBottom: '10px' }}
              className={!matches ? 'font-16 ' : 'small-font-14 '}
              onClick={() => {
                if (onFocus && currentlySelected) {
                  setClicked(!clicked);
                } else {
                  setClicked(true);
                }
                focusFunction(label + '_' + type);
              }}
            >
              <div className='flex-centered-between pointer legend-item'>
                <div className='flex-centered'>
                  <div className={'donut-legend-icon'} style={{ backgroundColor: iconColor }} />
                  <Tooltip title={t([`dashboard.${label}`, label])}>
                    <span
                      className={openAndSelected ? 'font-bold underline-text' : 'semi-bold underline-text'}
                      style={!matchesPhone ? { maxWidth: '110px', textOverflow: 'ellipsis', overflow: 'hidden' } : {}}
                      data-testid={openAndSelected ? 'donut-legend-bold-label' : 'donut-legend-label'}
                    >
                      {t([`dashboard.${label}`, label])}
                    </span>
                  </Tooltip>
                </div>
                <div key={`item-${index}`} className='flex'>
                  <div className='flex'>
                    {!isNaN(quantity) ? (
                      <span
                        data-testid='feature'
                        className={openAndSelected ? 'font-bold doughnut-dash-wrapper-legend-data-with-separator' : 'semi-bold doughnut-dash-wrapper-legend-data-with-separator'}
                        style={{ minWidth: '25px' }}
                      >
                        {quantity}
                      </span>
                    ) : (
                      <span></span>
                    )}
                    <span
                      data-testid='feature-perc'
                      className={openAndSelected ? 'font-bold doughnut-dash-wrapper-legend-data-with-separator' : 'doughnut-dash-wrapper-legend-data-with-separator'}
                      style={{ width: matchesPhone ? '47px' : '56px' }}
                    >
                      {`(${percFormat(status.data, total)})`}
                    </span>
                    <span data-testid='feature-value' className={openAndSelected ? 'font-bold' : 'semi-bold'} style={{ width: `${(matchesPhone ? 15 : 16) * maxLen}px`, textAlign: 'end' }}>
                      {CurrencyFormatter(store.currency, money)}
                    </span>
                  </div>
                </div>
              </div>
              {!!promo && showPromoLegend && (
                <div className='doughnut-dash-wrapper-legend-promo-container'>
                  <span style={{ marginLeft: '26px' }}>{t('dashboard.promo')}</span>
                  <span className='flex-container-end' style={!matchesPhone ? (!isNaN(quantity) ? { minWidth: '220px' } : { minWidth: '193px' }) : { minWidth: '160px' }}>
                    {CurrencyFormatter(store.currency, promo)}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Grid container className={`doughnut-dash-wrapper ${className || ''}`}>
      <Grid item>{title && title.length > 0 && <ChartTitle className='doughnut-dash-wrapper-title' title={title} />}</Grid>
      {data && data.length > 0 && total > 0 ? (
        <Grid item container className='doughnut-dash-wrapper-body'>
          <Grid item xs={12} lg={12} xl={clickableLegend ? 3 : 4} className='doughnut-dash-wrapper-chart-container'>
            <DoughnutReChart
              className='doughnut-dash-wrapper-chart'
              availableStatuses={availableStatuses}
              availableColors={availableColors}
              data={data}
              height={height}
              width={width}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            xl={clickableLegend ? 9 : 8}
            className={matchesXLgClickable ? 'doughnut-dash-wrapper-legend-container' : ''}
            style={matchesXLgClickable ? { paddingRight: '16px' } : {}}
          >
            {clickableLegend ? <ClickableLegend className='doughnut-dash-wrapper-legend' /> : <NonClickableLegend className='doughnut-dash-wrapper-legend' />}
          </Grid>
        </Grid>
      ) : (
        <NoDataChartDashboard />
      )}
    </Grid>
  );
};

DoughnutChartDashboardWrapper.propTypes = {
  /**
   * graphTitle: The title of the graph
   */
  title: PropTypes.string,
  /**
   * availableStatuses: all statuses to be displayed on chart and legend
   */
  availableStatuses: PropTypes.arrayOf(PropTypes.string),
  /**
   * availableColors: colors to use for each status
   */
  availableColors: PropTypes.arrayOf(PropTypes.string),
  /**
   * data
   */
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * width: of the chart
   */
  width: PropTypes.number,
  /**
   * height: of all the elements
   */
  height: PropTypes.number,
  /**
   * innerRadius: 0 is a pie chart. The higher it is, the bigger the "hole" at the center of the chart will be
   */
  innerRadius: PropTypes.number,
  /**
   * outerRadius: how "big" the chart is
   */
  outerRadius: PropTypes.number,
  /**
   * showPromoLegend: show promo legend
   */
  showPromoLegend: PropTypes.bool,
  /**
   * focusFunction: onclick legend element function
   */
  focusFunction: PropTypes.func,

  /**
   * type: kind of graphic
   */
  type: PropTypes.string,

  /**
   * onFocus: actual on focus element
   */
  onFocus: PropTypes.string,
  clickableLegend: PropTypes.bool,
};

DoughnutChartDashboardWrapper.defaultProps = {
  availableStatuses: ChartConstants.availableStatus,
  availableColors: ChartConstants.defaultColors,
  data: [],
  width: 500,
  height: 200,
  innerRadius: 40,
  outerRadius: 80,
  showPromoLegend: true,
  focusFunction: () => {},
  type: '',
  onFocus: '',
  clickableLegend: false,
};

export default DoughnutChartDashboardWrapper;
