import { memo } from 'react';
import { Header } from './components/Header';
import { Menu } from './components/Menu';
import { PlaceholderDashboard } from './components/PlaceholderDashboard';

const PageHome = () => {
  return (
    <>
      <Header />
      <Menu />
      <PlaceholderDashboard />
    </>
  );
};

export default memo(PageHome);
