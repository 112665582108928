import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PageTemplate from 'pages/PageTemplate';
import PropTypes from 'prop-types';
import CrmSnackbarFeedback from 'components/common/CrmSnackbarFeedback';
import CrmSnackbarError from 'components/common/CrmSnackbarError';
import CrmSnackbarAmber from 'components/common/CrmSnackbarAmber';
import { observer } from 'mobx-react';
import { useStore } from 'store/storeUtils';
import Client from 'client/Client';
import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';
import moment from 'moment';
import convertLanguage from 'utils/convertLanguage';

const PrivateRoute = observer((props) => {
  const [updateUser] = useMutation(Client.UPDATE_USER);
  const { i18n, t } = useTranslation();

  const store = useStore();

  const [newLang, setNewLang] = React.useState('');
  React.useEffect(() => {
    moment.locale(newLang);
  }, [newLang]);

  const setLanguage = (lang, locale) => {
    setNewLang(lang);

    const input = { email: store.loggedUser.email, language: lang, locale: locale, project_ids: store.loggedUser.project_ids };
    const oldLanguage = store.loggedUser && store.loggedUser.language;
    const oldLocale = store.loggedUser && store.loggedUser.locale;

    updateUser(Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser.id, input))
      .then(() => {
        store.setSnackbar(true, t('snackbar.successOperation'));
        store.setSystemLanguage(lang);
        store.setLoggedUserLang(lang, locale);
        i18n.changeLanguage(convertLanguage(lang, locale));
      })
      .catch((e) => {
        if (oldLanguage) {
          setNewLang(oldLanguage);
          store.setLoggedUserLang(oldLanguage, oldLocale);
          store.setSystemLanguage(oldLanguage);
          i18n.changeLanguage(convertLanguage(oldLanguage, oldLocale));
        }
        store.setSnackbarError(true, t('snackbar.failedOperation'));
        console.error(e);
      });
  };
  const { exact, path, ToRender, title, goBack, navbar } = props;
  return (
    <>
      <CrmSnackbarFeedback />
      <CrmSnackbarAmber />
      <CrmSnackbarError />
      <Route
        exact={exact}
        path={path}
        navbar={navbar}
        render={(props) => {
          return store.loggedUser ? (
            <PageTemplate BodyComponent={ToRender} title={title} goBack={goBack} setLanguage={setLanguage} {...props} />
          ) : !store.baseUrl ? (
            <></>
          ) : (
            <Redirect exact to={{ pathname: '/login', search: window.location.search }} />
          );
        }}
      />
    </>
  );
});

PrivateRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  ToRender: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  goBack: PropTypes.bool,
  breadcrumb: PropTypes.string,
};

PrivateRoute.defaultProps = {
  exact: false,
  goBack: false,
};

export default PrivateRoute;
