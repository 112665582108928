export const utilityGetDateWithTimezone = (date) => {
  const offset = date.getTimezoneOffset(); // in minuti
  const offsetHours = Math.abs(Math.floor(offset / 60)); // ore
  const offsetMinutes = Math.abs(offset % 60); // minuti
  const sign = offset > 0 ? '-' : '+';
  const offsetString = `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
  const updatedAfter = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(
    date.getMinutes(),
  ).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}${offsetString}`;
  return updatedAfter;
};
