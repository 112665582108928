const { quadrantCodeToArray, getFullQuadrantCode } = require('./quadrant');

// restituisce un array con gli id dei building dell'appartamento
export const getAppartmentBuildings = (apt) => {
  const buildings = apt.sides.map((side) => (typeof side.building === 'object' ? side.building.id : side.building));
  return buildings.filter((element, index, array) => array.indexOf(element) === index);
};

export const createBuildingsStructure = (appartments) => {
  const buildingsStructure = {};

  // per ogni appartamento
  appartments.forEach((apt) => {
    // calcolo i building
    const buildings = getAppartmentBuildings(apt);

    buildings.forEach((buildingId) => {
      if (!buildingsStructure[buildingId]) {
        // se il building non è presente in buildingsStructure lo aggiungo con piani vuoti
        buildingsStructure[buildingId] = {
          floors: {},
        };
      }

      if (!buildingsStructure[buildingId].floors[apt.floor]) {
        // se non è presente il piano dell'appartamento in buildingsStructure del building lo aggiungo con dati vuoti
        buildingsStructure[buildingId].floors[apt.floor] = {
          quadranti: [],
          accorpamenti: {},
          appartamenti: [],
        };
      }
      // aggiungo l'appartamento nel piano
      buildingsStructure[buildingId].floors[apt.floor].appartamenti.push(apt);

      // per ogni quadrante dell'appartamento
      apt.plan.quadrants.forEach((quadrant) => {
        // se in negli accorpamenti del piano dell'edificio di buildingsStructure non esiste il quadrante
        if (!buildingsStructure[buildingId].floors[apt.floor].accorpamenti[quadrant]) {
          // aggiungo come minAptPrice e maxAptPrice il prezzo dell'appartamento
          buildingsStructure[buildingId].floors[apt.floor].accorpamenti[quadrant] = {
            minAptPrice: apt.price,
            maxAptPrice: apt.price,
          };
        } else {
          // altrimenti
          // metto come minAptPrice il minore tra quello che avevo e il prezzo dell'appartamento
          const minAptprice = Math.min(buildingsStructure[buildingId].floors[apt.floor].accorpamenti[quadrant].minAptPrice, apt.price);
          // metto come maxAptPrice il maggiore tra quello che avevo e il prezzo dell'appartamento
          const maxAptPrice = Math.max(buildingsStructure[buildingId].floors[apt.floor].accorpamenti[quadrant].maxAptPrice, apt.price);
          buildingsStructure[buildingId].floors[apt.floor].accorpamenti[quadrant] = {
            minAptprice,
            maxAptPrice,
          };
        }
        const quadrantCodes = quadrantCodeToArray(quadrant, apt.floor);
        // per ogni quadrante del piano dell'appartamento
        quadrantCodes.forEach(({ code }) => {
          if (!buildingsStructure[buildingId].floors[apt.floor].quadranti.includes(code)) {
            // se non presente nei quadranti del piano dell'edificio allora aggiungi a buildingStructures
            buildingsStructure[buildingId].floors[apt.floor].quadranti.push(code);
          }
        });
      });
    });
  });
  // restituisco un'array con le chiavi dei building al cui interno c'è un oggetto con le chiavi di tutti i piani
  // in cui dentro accorpamento ci sono i quadranti con i min e max price degli appartamenti
  // gli appartamenti
  // i quadranti analizzati
  return buildingsStructure;
};

const generateInterestedFloorsMap = (appartments) => {
  const floorsMap = {};
  appartments.forEach((apt) => {
    if (apt.available && apt.enabled_desk) {
      const buildings = getAppartmentBuildings(apt);
      buildings.forEach((buildingId) => {
        if (!floorsMap[buildingId]) {
          floorsMap[buildingId] = {
            buildingId,
            floors: {},
          };
        }

        if (!floorsMap[buildingId].floors[apt.floor]) {
          floorsMap[buildingId].floors[apt.floor] = {
            interestedFloors: [],
          };
        }

        if (apt && apt.plan && apt.plan.quadrants) {
          apt.plan.quadrants.forEach((quadrantCode) => {
            const quadrantArray = quadrantCodeToArray(quadrantCode, apt.floor);
            const floors = quadrantArray.map((q) => q.floor).filter((v, i, a) => a.indexOf(v) === i);

            floors.forEach((floorNumber) => {
              if (!floorsMap[buildingId].floors[apt.floor].interestedFloors.includes(floorNumber)) {
                floorsMap[buildingId].floors[apt.floor].interestedFloors.push(floorNumber);
              }
            });
          });
        }
      });
    }
  });

  let updates;
  do {
    updates = false;

    const buildingKeys = Object.keys(floorsMap);
    for (let buildingIndex = 0; buildingIndex < buildingKeys.length; buildingIndex += 1) {
      const buildingKey = buildingKeys[buildingIndex];
      const building = floorsMap[buildingKey];

      const floorsKeys = Object.keys(building.floors);

      for (let floorIndex = 0; floorIndex < floorsKeys.length; floorIndex += 1) {
        const floorKey = floorsKeys[floorIndex];
        const floor = building.floors[floorKey];

        for (let interestedFloorsIndex = 0; interestedFloorsIndex < floor.interestedFloors.length; interestedFloorsIndex += 1) {
          const floorNumber = floor.interestedFloors[interestedFloorsIndex];

          for (let floorToInsertIndex = 0; floorToInsertIndex < floor.interestedFloors.length; floorToInsertIndex += 1) {
            const floorToInsert = floor.interestedFloors[floorToInsertIndex];

            // irrobustimento
            if (!floorsMap[buildingKey].floors[floorNumber]) {
              floorsMap[buildingKey].floors[floorNumber] = {
                interestedFloors: [],
              };
            }

            if (!floorsMap[buildingKey].floors[floorNumber].interestedFloors.includes(floorToInsert)) {
              updates = true;
              floorsMap[buildingKey].floors[floorNumber].interestedFloors.push(floorToInsert);
            }
          }
        }
      }
    }
  } while (updates);

  return floorsMap;
};

export const filterAccorpamenti = (accorpamenti, accorpamento, defaultFloor, accorpamentiToReturn) => {
  // prendo i codici quadranti
  const quadrants = quadrantCodeToArray(accorpamento.code, defaultFloor);

  // se cluster vuoto aggiungo l'accorpamento
  if (accorpamentiToReturn.length === 0) {
    accorpamentiToReturn.push(accorpamento);
  }

  // per ogni quadrante
  quadrants.forEach((q) => {
    const connectedAccorpamenti = getConnectedAccorpamenti(accorpamenti, q, defaultFloor);

    connectedAccorpamenti.forEach((acc) => {
      if (!accorpamentiToReturn.includes(acc)) {
        accorpamentiToReturn.push(acc);
        filterAccorpamenti(accorpamenti, acc, defaultFloor, accorpamentiToReturn);
      }
    });
  });
};

const getConnectedAccorpamenti = (accorpamenti, quadrant, defaultFloor) => {
  const accorpamentiToReturn = accorpamenti.filter((acc) => {
    const quadrants = quadrantCodeToArray(acc.code, defaultFloor);

    const toReturn = quadrants.some((q) => q.code === quadrant.code && q.floor === quadrant.floor);

    return toReturn;
  });

  return accorpamentiToReturn;
};

export const listaAccorpamenti = (appartments) => {
  const floorsMap = generateInterestedFloorsMap(appartments);
  const buildingsMap = {};

  appartments.forEach((apt) => {
    if (apt.available && apt.enabled_desk) {
      const buildings = getAppartmentBuildings(apt);
      buildings.forEach((buildingId) => {
        floorsMap[buildingId].floors[apt.floor].interestedFloors.forEach((floorNumber) => {
          // creo la chiave valore nella mappa se non c'è già
          if (!buildingsMap[buildingId]) {
            buildingsMap[buildingId] = {
              buildingId,
              floors: {},
            };
          }

          if (!buildingsMap[buildingId].floors[floorNumber]) {
            buildingsMap[buildingId].floors[floorNumber] = {
              quadranti: [],
              accorpamenti: [],
              appartamenti: [],
            };
          }

          apt.plan.quadrants.forEach((quadrant) => {
            const quadrantCodeToInsert = getFullQuadrantCode(quadrant, apt.floor);

            // inserisco i quadranti nell'array di accorpamenti
            if (!buildingsMap[buildingId].floors[floorNumber].accorpamenti.includes(quadrantCodeToInsert)) {
              buildingsMap[buildingId].floors[floorNumber].accorpamenti.push({ ...apt, code: quadrantCodeToInsert });
            }
          });
        });
      });
    }
  });

  return buildingsMap;
};
