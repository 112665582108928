import React, { useEffect } from 'react';
import { useStore } from 'store/storeUtils';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

const LogoutListener = observer(() => {
  const store = useStore();
  const history = useHistory();

  const clearStore = () => {
    try {
      store.logOut();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (store.redirectionToSsoNeeded) {
      clearStore();
      history.push('/login');
    }
  }, [store.redirectionToSsoNeeded]);

  return <></>;
});

export default LogoutListener;
