export const getCustomColumnsTableByProjectAndClient = (project_id, loggedUserId) => {
  const customColumnsTable = JSON.parse(localStorage.getItem('customColumnsTable'));
  if (customColumnsTable) {
    return customColumnsTable?.[project_id]?.[loggedUserId] ?? [];
  }
  return [];
};

export const setCustomColumnsTableByProjectAndClient = (project_id, loggedUserId, val) => {
  const customColumnsTable = JSON.parse(localStorage.getItem('customColumnsTable'));
  if (customColumnsTable) {
    const newCustomColumnsTable = {
      ...customColumnsTable,
      [project_id]: { ...(customColumnsTable?.[project_id] ?? {}), [loggedUserId]: val },
    };
    localStorage.setItem('customColumnsTable', JSON.stringify(newCustomColumnsTable));
  } else {
    localStorage.setItem('customColumnsTable', JSON.stringify({ [project_id]: { [loggedUserId]: val } }));
  }
};
