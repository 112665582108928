import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useStore } from 'store/storeUtils.js';
import HorizontalStackBarReChart from 'components/charts/HorizontalStackBarReChart';
import CurrencyFormatter from 'utils/CurrencyFormatter';
import { BOOKED, MAX_LISTINO, MAX_PROMO, MIN_LISTINO, MIN_PROMO } from 'constants/bookingThresholds';
import { COMPROMESSO, PROPOSTA, ROGITATO } from 'constants/bookingStatuses';
import { percFormat } from 'utils/formatting';
import { Grid } from '@material-ui/core';
import 'styles/charts.scss';
import 'styles/DasEconomicDashboard.scss';
import { useTranslation } from '@tecma/i18n';
import colors from '../../styles/colors.module.scss';

import ChartTitle from 'components/charts/ChartTitle';

const DasEconomicDashboard = ({ title, bookedValues, minListino, maxListino, minPromo, maxPromo, withFeatures = false, detailed = false, barSize = 40, graphicHeight = 100, style = {}, statuses }) => {
  const store = useStore();
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const { t } = useTranslation();
  const myFixed = (x, d) => {
    if (!d) return x.toFixed(d); // don't go wrong if no decimal
    return x.toFixed(d).replace(/\.?0+$/, '');
  };

  const extractLegendData = (data) => data.filter((elem) => elem.legend).map((elem) => ({ ...elem.legend, color: elem.color }));
  const extractLegendDataFiltered = (data) => extractLegendData(data.filter((elem) => statuses?.includes(elem.key)));
  const buildThresholdObj = (value, description) => ({
    id: description.labelId,
    value: value,
    color: description.color,
    legend: {
      title: t([description.labelId, description.defaultLabel]),
      subTitle: CurrencyFormatter(store.currency, value),
    },
  });
  const buildDetailedDataObj = (value, description, count) => ({
    key: description.status,
    value: value,
    color: description.color,
    legend: {
      title: `${t([description.labelId, description.defaultLabel])}`,
      subTitle: `${typeof count !== 'undefined' ? count + ' | ' : ''}${percFormat(value, maxListino)} | ${CurrencyFormatter(store.currency, value)}`,
    },
  });

  const summaryData = [
    {
      key: 'booked',
      value: bookedValues.booked,
      color: colors.booked,
      legend: {
        title: t('label.ActualBooked'),
        subTitle: t(withFeatures ? 'dashboard.das.actualBookedApartmentsAndFeaturesDescription' : 'dashboard.das.actualBookedApartmentsDescription'),
        count: withFeatures ? false : bookedValues.count,
        percentage: myFixed((bookedValues.booked / maxListino) * 100, 1),
        value: CurrencyFormatter(store.currency, bookedValues.booked),
      },
    },
  ];

  const detailedData = [
    buildDetailedDataObj(bookedValues.proposta, PROPOSTA, bookedValues.propostaCount),
    buildDetailedDataObj(bookedValues.compromesso, COMPROMESSO, bookedValues.compromessoCount),
    buildDetailedDataObj(bookedValues.rogitato, ROGITATO, bookedValues.rogitatoCount),
  ];

  const thresholds = [
    {
      id: 'bookedValuesBooked',
      value: bookedValues.booked,
      color: BOOKED.color,
      width: 2,
      label: CurrencyFormatter(store.currency, bookedValues.booked),
      labelColor: BOOKED.labelColor,
      labelProps: {
        position: bookedValues.booked >= (maxListino / 100) * 50 ? 'insideBottomRight' : 'insideBottomLeft',
        dy: 0,
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: matchesPhone ? '10px' : '12px',
      },
    },
    buildThresholdObj(minPromo, MIN_PROMO),
    buildThresholdObj(minListino, MIN_LISTINO),
    buildThresholdObj(maxPromo, MAX_PROMO),
    buildThresholdObj(maxListino, MAX_LISTINO),
  ];

  const SummaryLegend = ({ data, className }) => (
    <Grid container xs={12} className={className} style={{}}>
      <Grid xs={12} className='flex-centered-between' style={{}}>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <div className='donut-legend-icon' style={{ backgroundColor: data.color }}></div>
          <div className='das-economic-dashboard-legend-item-title'>{data.title}</div>
        </Grid>

        <Grid item>
          {data.count ? (
            <span data-testid='actual-booked' className='semi-bold doughnut-dash-wrapper-legend-data-with-separator' style={{ minWidth: '25px', fontSize: '14px' }}>
              {data.count}
            </span>
          ) : (
            <></>
          )}
          <span className='doughnut-dash-wrapper-legend-data-with-separator' style={{ minWidth: '50px', fontSize: '14px' }}>
            {data.percentage}%
          </span>
          <span className='semi-bold' style={{ minWidth: '77px', textAlign: 'end', fontSize: '14px' }}>
            {data.value}
          </span>
        </Grid>
      </Grid>
      <Grid item style={{ display: 'flex', alignItems: 'center', paddingLeft: '25px', textAlign: 'justify' }}>
        <div className='das-economic-dashboard-legend-item-subtitle das-economic-dashboard-summary-legend-subtitle'>{data.subTitle}</div>
      </Grid>
    </Grid>
  );

  const DetailedLegend = ({ elements, className }) => (
    <Grid container className={className}>
      {elements.map((element, i) => {
        return (
          <Grid
            key={'i' + i}
            item
            container
            className='das-economic-dashboard-legend-item'
            xs={12}
            sm={4}
            justifyContent={matchesPhone ? 'space-between' : 'flex-start'}
            direction={matchesPhone ? 'row' : 'column'}
            alignItems={matchesPhone ? 'center' : 'flex-start'}
          >
            <Grid item style={{ display: 'flex', alignItems: matchesPhone ? 'flex-start' : 'center' }}>
              <div className='donut-legend-icon' style={{ backgroundColor: element.color }}></div>
              <div className='das-economic-dashboard-legend-item-title'>{element.title}</div>
            </Grid>
            {element.subTitle && (
              <Grid item>
                <div
                  className='das-economic-dashboard-legend-item-subtitle das-economic-dashboard-detailed-legend-subtitle'
                  style={{ color: colors.booked, paddingLeft: !matchesPhone ? '25px' : 'initial' }}
                >
                  {element.subTitle}
                </div>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );

  const ThresholdLegend = ({ elements }) => (
    <Grid container spacing={matchesPhone ? 0 : 1}>
      {elements.map((element, i) => {
        return (
          <Grid
            key={'i' + i}
            item
            container
            className='das-economic-dashboard-legend-item das-economic-dashboard-thresholds-legend-item '
            xs={12}
            sm={6}
            style={matchesPhone ? {} : i % 2 ? { paddingLeft: '8px' } : { paddingRight: '8px' }}
          >
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <div className='donut-legend-icon' style={{ backgroundColor: element.color }}></div>
              <div className='das-economic-dashboard-legend-item-title'>{element.title}</div>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {element.subTitle && <div className='das-economic-dashboard-legend-item-subtitle das-economic-dashboard-thresholds-legend-subtitle'>{element.subTitle}</div>}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <Grid container xs={12} className='das-economic-dashboard' style={style}>
      <Grid item xs={12} className='das-economic-dashboard-title-container'>
        <ChartTitle title={title} className='das-economic-dashboard-title' />
      </Grid>
      <Grid item xs={12} className='das-economic-dashboard-chart-container'>
        <HorizontalStackBarReChart
          className='das-economic-dashboard-chart'
          values={detailed ? detailedData : summaryData}
          thresholds={thresholds}
          barSize={barSize}
          graphicHeight={graphicHeight}
          maxValue={maxListino}
        />
      </Grid>
      <Grid item container xs={12} className='das-economic-dashboard-legends-container'>
        <Grid item xs={12} className='das-economic-dashboard-data-legend-container'>
          {detailed ? (
            <DetailedLegend className='das-economic-dashboard-data-legend' elements={extractLegendDataFiltered(detailedData)} />
          ) : (
            <SummaryLegend className='das-economic-dashboard-data-legend' data={extractLegendData(summaryData)[0]} />
          )}
        </Grid>
        <Grid item xs={12} className='das-economic-dashboard-thresholds-legend-container'>
          <ThresholdLegend className='das-economic-dashboard-thresholds-legend' elements={extractLegendData(thresholds)} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DasEconomicDashboard;
