import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import variables from 'styles/colors.module.scss';
import { CsvBuilder } from 'filefy';
import 'styles/table.scss';
import { cloneDeep } from 'lodash';
import { useTranslation } from '@tecma/i18n';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import searchLens from 'images/assets/search-table.svg';

/**
 * customized table based on material-table
 * @component
 * @see {@link https://material-table.com/ material-table}
 */
const CrmTable = (props) => {
  const handleExportCsv = (columns, renderData, exportFileName) => {
    const csvColumns = columns.filter((columnDef) => {
      return columnDef.field && columnDef.export !== false;
    });

    const data = renderData.map((rowData) => csvColumns.map((columnDef) => rowData[columnDef.field]));

    new CsvBuilder(exportFileName ? exportFileName + '.csv' : 'export.csv')
      .setDelimeter(',')
      .setColumns(csvColumns.map((columnDef) => columnDef.title))
      .addRows(data)
      .exportFile();
  };

  const {
    title,
    data,
    columns,
    paging,
    searchText,
    extraComponents,
    pageSize,
    pageSizeOptions,
    containerClass,
    search,
    exportButton = false,
    exportFileName,
    saveSearch,
    filtering,
    saveSort,
    customEmptyMessage,
    noColumnsMessage,
    ...rest
  } = props;

  const theme = useTheme();
  const matchPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const matchSm = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  let customStyle = {};
  let alignment = 'right';

  if (matchSm) {
    customStyle = { ...customStyle, position: 'relative', bottom: '-15px' };
    alignment = 'left';
  }

  if (matchPhone) {
    customStyle = { ...customStyle, width: '100%', position: 'absolute', bottom: '-50px' };
  }

  const finalOptions = {
    sorting: true,
    search: search,
    searchText: searchText,
    emptyRowsWhenPaging: false,
    paging: paging && data && data.length > 0,
    headerStyle: {
      backgroundColor: variables.CARD_BACKGROUND_LIGHT,
      fontFamily: variables.DEFAULT_FONT + '!important',
      fontWeight: variables.LIGHT + '!important',
    },
    rowStyle: {
      height: '80px',
    },

    pageSize: pageSize,
    pageSizeOptions: pageSizeOptions,
    exportFileName: exportFileName,
    exportButton: exportButton,
    exportAllData: exportButton,
    exportCsv: (columns, renderData) => handleExportCsv(columns, renderData, exportFileName),
    filtering: filtering,
    searchFieldStyle: customStyle,
    searchFieldAlignment: alignment,
  };

  const defaultComponents = search
    ? {}
    : {
        Toolbar: () => '',
      };

  const mergedComponents = Object.assign({}, defaultComponents, extraComponents);

  const { t } = useTranslation();

  const editable = cloneDeep(data);

  return (
    <div className={containerClass}>
      <MaterialTable
        title={title}
        components={mergedComponents}
        columns={columns}
        data={editable}
        style={{ backgroundColor: 'inherit' }}
        icons={{
          SortArrow: forwardRef((props, ref) => <KeyboardArrowDownIcon {...props} ref={ref} />),
          Search: forwardRef((props, ref) => <CrmSvgLoader data={searchLens} alt='lens' id={'lens-in-table'} {...props} ref={ref} />),
        }}
        options={finalOptions}
        onSearchChange={saveSearch}
        onOrderChange={saveSort}
        localization={{
          body: {
            emptyDataSourceMessage: !columns.length ? t(noColumnsMessage) : t(customEmptyMessage),
          },
          toolbar: {
            searchTooltip: t('general.search'),
            searchPlaceholder: t('general.search'),
            exportName: t('general.exportCSV'),
            exportAriaLabel: t('general.export'),
          },
          pagination: {
            labelDisplayedRows: t('general.displayedRows'),
            labelRowsSelect: t('general.rows'),
            labelRowsPerPage: t('general.rowsPerPage'),
            firstAriaLabel: t('general.firstPageAriaLabel'),
            firstTooltip: t('general.firstPageTooltip'),
            previousAriaLabel: t('general.previousPageAriaLabel'),
            previousTooltip: t('general.previousPageTooltip'),
            nextAriaLabel: t('general.nextPageAriaLabel'),
            nextTooltip: t('general.nextPageTooltip'),
            lastAriaLabel: t('general.lastPageAriaLabel'),
            lastTooltip: t('general.lastPageTooltip'),
          },
        }}
        {...rest}
      />
    </div>
  );
};
/** prop types */
CrmTable.propTypes = {
  /** extraComponents: additional custom components */
  extraComponents: PropTypes.object,
  /** containerClass: SCSS class for table container */
  containerClass: PropTypes.string,
  /** customEmptyMessage: Custom message when no data is available */
  customEmptyMessage: PropTypes.string,
  /** noColumnsMessage: Custom message when no columns are setted */
  noColumnsMessage: PropTypes.string,
  /** title: string or html node for table title */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** data: an array of data to be displayed */
  data: PropTypes.arrayOf(PropTypes.object),
  /** columns: column definitions */
  columns: PropTypes.arrayOf(PropTypes.object),
  /** paging: boolean for pagination ability */
  paging: PropTypes.bool,
  /** search: boolean for searching ability */
  search: PropTypes.bool,
  /** pageSize: default page size (if pagination = true) */
  pageSize: PropTypes.number,
  /** pageSizeOptions: array of available page sizes */
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  /** filtering: boolean for filtering ability */
  filtering: PropTypes.bool,
};

CrmTable.defaultProps = {
  containerClass: '',
  searchText: '',
  customEmptyMessage: 'general.noDataToShow',
  noColumnsMessage: 'general.noColumnsTable',
  title: '',
  data: [],
  search: false,
  columns: [],
  paging: false,
  pageSize: 5,
  pageSizeOptions: [5, 10, 20],
  filtering: false,
};

export default CrmTable;
