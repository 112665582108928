import i18n from '@tecma/i18n';
/**
 * a number formatter for currency
 */

export const DatesFormatter = (date, options = { day: 'numeric', month: 'short', year: 'numeric' }) => {
  try {
    return new Intl.DateTimeFormat(i18n.language || 'it-IT', options).format(date);
  } catch {
    return new Intl.DateTimeFormat('it-IT', options).format(date);
  }
};

export const DatesFormatterRange = (startDate, endDate, options = { year: '2-digit', month: 'numeric', day: 'numeric' }) => {
  try {
    return new Intl.DateTimeFormat(i18n.language || 'it-IT', options).formatRange(startDate, endDate);
  } catch {
    return new Intl.DateTimeFormat('it-IT', options).format(startDate, endDate);
  }
};
