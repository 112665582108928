import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { memo } from 'react';

export const ButtonShortcut = memo(({ onClick, label }) => (
  <Button className='menu-home-button' onClick={onClick} disableRipple>
    <AddIcon
      style={{
        width: 16,
        height: 16,
      }}
    />
    <span className='menu-button-label'>{label}</span>
  </Button>
));
