import Cookies from 'js-cookie';

class UserService {
  constructor(projectBaseUrl) {
    const projectHost = projectBaseUrl.endsWith('/') ? projectBaseUrl.slice(0, -1) : projectBaseUrl;
    this.baseUserURL = `${projectHost}${process.env.REACT_APP_USER_API_BASE_URL}`;
    this.initRequest = {
      method: 'GET',
      referrerPolicy: 'unsafe-url',
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}`, 'Content-Type': 'application/json' },
    };
  }

  async subscribeToNewsletterApi(userId, projectId, { projectHostKey, privacyPolicy, projectDefaultLang, subscription, projectArea = 'sell' }) {
    const response = await fetch(`${this.baseUserURL}newsletter/${userId}/${projectId}`, {
      ...this.initRequest,
      method: 'POST',
      body: JSON.stringify({
        projectHostKey,
        projectArea,
        privacyPolicy,
        projectDefaultLang,
        subscription,
      }),
    });
    if (!response.ok) {
      throw new Error(`Status: ${response.status}`);
    }
    return response;
  }
}

export default UserService;
