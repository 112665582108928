import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList, Cell } from 'recharts';
import colors from '../../styles/colors.module.scss';

const renderCustomizedLabelName = (props) => {
  const { x, y, value } = props;
  return (
    <text x={x + 1} y={y - 10} className={'label'}>
      {value}
    </text>
  );
};

const renderCustomizedLabelPerc = (props) => {
  const { x, y, width, value, matches } = props;
  return (
    <text x={!matches ? x + width - 80 : x + width - 55} y={y - 10} className='label' textAnchor='end'>
      {value}
    </text>
  );
};

const renderCustomizedNumber = (props) => {
  const { x, y, width, value, matches } = props;
  return (
    <text x={!matches ? x + width - 50 : x + width - 35} y={y - 10} className='number-appointments' textAnchor='end'>
      {value}
    </text>
  );
};

const renderCustomizedNumberTotal = (props) => {
  const { x, y, width, value } = props;

  return (
    <text x={x + width} y={y - 10} className='number-appointments' textAnchor='end'>
      su {value}
    </text>
  );
};

const renderCustomizedLineSx = (props) => {
  const { x, y, width, id, matches } = props;
  if (id === '0-0') return <></>;
  return <rect x={!matches ? x - 16 : x} y={y - 35} width={width} height='1' style={{ fill: '#CACBD3' }}></rect>;
};
const renderCustomizedLineDx = (props) => {
  const { x, y, width, id, matches } = props;
  if (id === '0-0') return <></>;
  return <rect x={!matches ? x - 16 : x} y={y - 35} width={!matches ? width + 16 : width} height='1' style={{ fill: '#CACBD3' }}></rect>;
};

const TotalAppointmentsView = (props) => {
  const { data } = props;
  const [appointments, setAppointments] = React.useState([]);

  React.useEffect(() => {
    setAppointments([...data]);
  }, [data]);

  const matchesMobile = useMediaQuery('(max-width:600px)');

  return (
    <div>
      <ResponsiveContainer height={350}>
        <BarChart layout='vertical' data={appointments} stackOffset='expand'>
          <XAxis hide type='number' />
          <YAxis hide type='category' dataKey='name' stroke='#000000' fontSize='14' />
          <Bar dataKey='number' barSize={15} stackId='a'>
            {appointments.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors.graph_third_color} />
            ))}
            <LabelList dataKey='name' position='top' content={renderCustomizedLabelName} />
            <LabelList matches={matchesMobile} id='0' dataKey='number' position='top' content={renderCustomizedLineSx} />
          </Bar>
          <Bar dataKey='differenceTotal' fill='#CCCCCC' stackId='a'>
            <LabelList matches={matchesMobile} dataKey='percentuale' position='top' content={renderCustomizedLabelPerc} />
            <LabelList matches={matchesMobile} dataKey='number' position='top' content={renderCustomizedNumber} />
            <LabelList dataKey='total' position='top' content={renderCustomizedNumberTotal} />
            <LabelList matches={matchesMobile} id='0' dataKey='differenceTotal' position='top' content={renderCustomizedLineDx} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TotalAppointmentsView;
