import React from 'react';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, ReferenceLine, Label } from 'recharts';
import PropTypes from 'prop-types';
import 'styles/charts.scss';

/**
 * HorizontalStackBarChart
 * based on recharts
 */
const HorizontalStackBarReChart = (props) => {
  const { graphicHeight, values, thresholds, maxValue, backgroundColor = '#E8E9EB', width = '100%', barSize } = props;

  let progress = 0;

  const data = values.reduce((acc, bar) => {
    acc[bar.key] = bar.value || 0;
    progress += bar.value || 0;
    return acc;
  }, {});

  data._remaining = maxValue - progress;

  const CustomReferenceLine = (props) => {
    const gap = (graphicHeight - barSize) / 2;

    return (
      <svg>
        <line {...props} y1={gap + barSize} y2={gap} />
      </svg>
    );
  };

  return (
    <ResponsiveContainer width={width} height={graphicHeight} className='horizontal-stack-bar-chart'>
      <BarChart layout='vertical' data={[data]} stackOffset='expand' margin={{ left: 0, right: 0 }}>
        <XAxis hide type='number' />
        <YAxis hide type='category' />
        {values.map((value) => {
          return <Bar key={value.key} dataKey={value.key} fill={value.color} legendType={value.legendType} stackId='a' barSize={barSize} />;
        })}
        {data._remaining !== 0 && <Bar dataKey={`_remaining`} fill={backgroundColor} legendType={'none'} stackId='a' />}
        {thresholds?.map((threshold) => (
          <ReferenceLine key={threshold.id} x={threshold.value / maxValue} stroke={threshold.color} strokeWidth={threshold.width} shape={(props) => <CustomReferenceLine {...props} />}>
            <Label value={threshold.label} fill={threshold.labelColor || '#000000'} {...threshold.labelProps} />
          </ReferenceLine>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

HorizontalStackBarReChart.propTypes = {
  /**
   * graphicHeight: of all the elements
   */
  graphicHeight: PropTypes.number,
  /**
   * width: of all the elements
   */
  width: PropTypes.string,
  /**
   * values: values descriptions
   */
  values: PropTypes.array.isRequired,
  /**
   * thresholds: thresholds descriptions
   */
  thresholds: PropTypes.array,
  /**
   * maxValue: max value corresponding to 100% completion
   */
  maxValue: PropTypes.number,
  /**
   * backgroundColor: graph background color (not completed portion)
   */
  backgroundColor: PropTypes.string,
};

HorizontalStackBarReChart.defaultProps = {
  graphicHeight: 100,
  width: '100%',
  thresholds: [],
  maxValue: 100,
  backgroundColor: '#E8E9EB',
};

export default HorizontalStackBarReChart;
