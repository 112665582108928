export const defaultInputProps = {
    classes: {
        root: "input-default",
        underline: "always-solid",
        input: "ellipsis small-font-14"
    }
};

export const defaultInputAutocompleteLabelProps = {
    classes: {
        root: "font-14 medium",
        shrink: "shrink-down",
    }
};

export const upInputAutocompleteLabelProps = {
    classes: {
        root: "font-14 medium",
        shrink: "shrink-up",
    }
};

export const defaultInputLabelProps = {
    classes: {
        root: "font-14 medium",
        shrink: "shrink-down",
        }
};