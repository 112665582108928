import { useCallback, useState } from 'react';
import { ClickAwayListener, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import CrmSvgLoader from 'components/common/CrmSvgLoader';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      maxWidth: '13.5rem',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      backgroundColor: 'rgba(59, 59, 59, 0.90)',
      padding: '0.5rem',
    },
  }),
);

const IconWithTooltip = ({ title, className, iconData, ...rest }) => {
  /**
   * Using controlled style to force closing on click. This is to avoid bug of tooltip being open
   * on page change.
   */
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const classes = useStyles();

  const handleTooltipClose = useCallback(() => {
    setTooltipOpen(false);
  }, []);
  const handleTooltipOpen = useCallback(() => {
    setTooltipOpen(true);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip classes={{ tooltip: classes.tooltip }} open={tooltipOpen} onClose={handleTooltipClose} onOpen={handleTooltipOpen} onClick={handleTooltipClose} title={title}>
        <div>
          <CrmSvgLoader className={className} data={iconData} {...rest} />
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default IconWithTooltip;
