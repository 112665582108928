import React from 'react';
import PropTypes from 'prop-types';
import Portal from '@material-ui/core/Portal';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CrmButton from 'components/common/CrmButton';
import { Trans } from '@tecma/i18n';
import colors from '../../styles/colors.module.scss';
import 'styles/modal.scss';

/**
 * A default, simple error modal
 * based on material-ui Dialog
 * @component
 * @see {@link https://material-ui.com/api/dialog/ Dialog API}
 */
const CrmSimpleModal = (props) => {
  const {
    disablePrimary,
    title,
    children,
    onClose,
    dialogClasses,
    extraContentClasses,
    actionToDo,
    buttonLabel,
    cancelLabel,
    cancelAction,
    cancelButtonExtraClass,
    open,
    maxWidth,
    colorActionButton,
    extraClassesTitle,
    testIdActionButton,
    customConfirmButton,
    paperOverrideClasses,
    spacingBetweenContentAndButtons,
    titleColor,
  } = props;

  const action = () => {
    actionToDo();
    onClose();
  };

  const cancel = () => {
    cancelAction();
    onClose();
  };

  return (
    <Portal>
      <Dialog maxWidth={maxWidth} open={open} classes={dialogClasses} onClose={onClose} PaperProps={paperOverrideClasses ? paperOverrideClasses : {}}>
        <DialogTitle
          classes={{
            root: `modal-title light ${extraClassesTitle}`,
          }}
          disableTypography
          style={titleColor ? { color: `${titleColor}` } : {}}
        >
          {title}
        </DialogTitle>
        <DialogContent
          classes={{
            root: `modal-content ${extraContentClasses}`,
          }}
        >
          {children}
        </DialogContent>
        <DialogActions classes={{ root: 'modal-actions' }} style={spacingBetweenContentAndButtons ? spacingBetweenContentAndButtons : {}}>
          {cancelAction && (
            <CrmButton onClick={cancel} className={cancelButtonExtraClass ? cancelButtonExtraClass : 'modal-cancel-button'}>
              {cancelLabel}
            </CrmButton>
          )}
          {customConfirmButton ? (
            customConfirmButton
          ) : (
            <CrmButton onClick={action} disabled={disablePrimary} className='modal-action-button' data-testid={testIdActionButton} style={{ backgroundColor: `${colorActionButton}` }}>
              {buttonLabel}
            </CrmButton>
          )}
        </DialogActions>
      </Dialog>
    </Portal>
  );
};

CrmSimpleModal.defaultProps = {
  extraContentClasses: '',
  extraClassesTitle: '',
  maxWidth: 'sm',
  dialogClasses: {},
  title: '',
  disablePrimary: false,
  open: false,
  buttonLabel: 'Ok',
  cancelLabel: <Trans i18nKey={'general.back'} />,
  onClose: () => {},
  actionToDo: () => {},
  colorActionButton: colors.accent,
  testIdActionButton: '',
  titleColor: 'black',
};

/** prop types */
CrmSimpleModal.propTypes = {
  /** extraContentClasses: addtional classes for the dialog content */
  extraContentClasses: PropTypes.string,
  extraClassesTitle: PropTypes.string,
  /** maxWidth: sets max-width of container (material-ui prop) */
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  /** dialogClasses  classes for the dialog container */
  dialogClasses: PropTypes.object,
  /** disablePrimary: condition to disable primary button */
  disablePrimary: PropTypes.bool,
  /** title: string */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** buttonLabel: label for the primary button */
  buttonLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** cancelLabel: label for the secondary button */
  cancelLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** open: required boolean */
  open: PropTypes.bool.isRequired,
  /** onClose: required action */
  onClose: PropTypes.func.isRequired,
  /** actionToDo: additional action for the primary button */
  actionToDo: PropTypes.func,
  /** cancelAction: additional action for the secondary button. If set, the button is rendered */
  cancelAction: PropTypes.func,
  /** children: modal content */
  children: PropTypes.node,
  /** string: action button color */
  colorActionButton: PropTypes.string,
  /**testIdActionButtin: id test action button */
  testIdActionButton: PropTypes.string,
  /**titleColor: color of the title */
  titleColor: PropTypes.string,
};

export default CrmSimpleModal;
