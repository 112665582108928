import { useMemo } from 'react';
import { useStore } from '../storeUtils';

export const useLoggedUser = () => {
  const store = useStore();
  const firstName = useMemo(() => store.loggedUser.firstName, [store.loggedUser.firstName]);
  const lastName = useMemo(() => store.loggedUser.lastName, [store.loggedUser.lastName]);

  return { firstName, lastName };
};
