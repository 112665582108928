import VisitedAppartmentInput from 'models/VisitedAppartmentInput';
import { cloneDeep, pick } from 'lodash';

const dataToKeep = [
  'id',
  'firstName',
  'lastName',
  'email',
  'status',
  'interested_appartments',
  'selected_appartments',
  'avatar',
  'tel',
  'city',
  'birthdate',
  'createdOn',
  'trattamento',
  'profilazione',
  'marketing',
  'note',
  'date_lead',
  'coniuge',
  'myhome_version',
  'myhome_pro',
  'additionalInfo',
  'reserved',
  'notificationLanguage',
];
/**
 * Creates a ClientInput from a Client obj and a list of Appartments
 * @param {obj} obj
 * @param {Array} appartments
 */
const createClientInputFromStore = (obj, appartments) => {
  if (!obj) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      status: 'lead',
      city: '',
      tel: '',
      avatar: undefined,
      birthdate: null,
      trattamento: false,
      profilazione: false,
      marketing: false,
      myhome_version: null,
      note: '',
      interested_appartments: [],
      selected_appartments: [],
      info: {},
      coniuge: {},
      family: {},
      user: '',
      additionalInfo: {},
      date_lead: null,
      privacyInformation: [],
      notificationLanguage: null,
    };
  }
  return {
    info: obj.info ? cloneDeep(obj.info) : {},
    coniuge: obj.coniuge || {},
    family: obj.family || {},
    firstName: obj.firstName || '',
    lastName: obj.lastName || '',
    email: obj.email || '',
    status: obj.status || 'lead',
    city: obj.city || '',
    tel: obj.tel || '',
    avatar: obj.avatar || undefined,
    notificationLanguage: obj.notificationLanguage,
    myhome_pro: obj.myhome_pro || false,
    birthdate: obj.birthdate ? obj.birthdate : null,
    date_lead: obj.date_lead ? obj.date_lead : null,
    myhome_version: obj.myhome_version || null,
    trattamento: obj.trattamento || false,
    profilazione: obj.profilazione || false,
    marketing: obj.marketing || false,
    note: obj.note || '',
    interested_appartments: obj.interested_appartments
      ? VisitedAppartmentInput.createVisitedAppartmentFromFilteredAppartments(
          appartments.filter((el) => obj.interested_appartments.map((sa) => sa.appartment).includes(el.id)),
          'interesse',
        )
      : [],
    selected_appartments: obj.selected_appartments
      ? VisitedAppartmentInput.createVisitedAppartmentFromFilteredAppartments2(appartments.filter((el) => obj.selected_appartments.map((sa) => sa.appartment).includes(el.id)))
      : [],
    user: obj.user,
    additionalInfo: obj.additionalInfo ? cloneDeep(obj.additionalInfo) : {},
    privacyInformation: obj.privacyInformation || undefined,
    appointee: obj.appointee?.id
  };
};

const getAdditionalInfoAndAppointee = (obj) => {
  const { additionalInfo, appointee } = obj;
    if (appointee && additionalInfo.vendor_di_riferimento?.trim()) {
      if (appointee !== additionalInfo.vendor_di_riferimento) {
        const { vendor_di_riferimento, ...newAdditionalInfo } = additionalInfo;
        return {
          additionalInfo: newAdditionalInfo,
          appointee,
        };
      }
      return {
        additionalInfo,
        appointee: undefined,
      };
    }
    return {
      additionalInfo,
      appointee,
    };
};

/** parses a ClientInput object before sending it to BE */
const toClientInput = (obj) => {
  if (!obj) {
    return {};
  }
  return {
    firstName: obj.firstName,
    lastName: obj.lastName,
    email: obj.email,
    status: obj.status,
    city: obj.city,
    avatar: obj.avatar || undefined,
    tel: obj.tel,
    birthdate: obj.birthdate ? obj.birthdate.toString() : null,
    trattamento: obj.trattamento,
    profilazione: obj.profilazione,
    marketing: obj.marketing,
    note: obj.note,
    date_lead: obj.date_lead,
    source: obj.source,
    motivazione: obj.motivazione,
    tempo_ricerca: obj.tempo_ricerca,
    stato_civile: obj.stato_civile,
    caratteristiche: obj.caratteristiche,
    mutuo: obj.mutuo,
    notificationLanguage: obj.notificationLanguage,
    leva: obj.leva,
    createdBy: obj.createdBy,
    arredo: obj.arredo,
    budget: obj.budget,
    alternative: obj.alternative,
    prima_casa: obj.prima_casa,
    family: obj.family,
    coniuge: obj.coniuge,
    myhome_pro: obj.myhome_pro || false,
    myhome_version: obj.myhome_version ? obj.myhome_version : null,
    privacyInformation: obj.privacyInformation || undefined,
    ...getAdditionalInfoAndAppointee(obj)
    
    //interested_appartments: VisitedAppartmentInput.toVisitedAppartmentList(obj.interested_appartments),
    //selected_appartments: VisitedAppartmentInput.toVisitedAppartmentList(obj.selected_appartments)
  };
};

const clientRenewInput = (oriClient) => {
  const client = { ...oriClient };
  client.city = client.city ? client.city : '';
  client.avatar = client.avatar ? client.avatar : '';
  client.reserved = false;
  return pick(client, dataToKeep);
  /* 
    clientToUpdt.reserved = false;
    delete clientToUpdt.tableData;
    delete clientToUpdt.nAppuntamenti;
    delete clientToUpdt.nPreventiviRichiesti;
    delete clientToUpdt.appuntamenti;
    delete clientToUpdt.info;
    delete clientToUpdt.user;
    delete clientToUpdt.confirmed;
    delete clientToUpdt.myhomeStatus;
    delete clientToUpdt.lastAccess;
    delete clientToUpdt.dateOperationFailed;
    clientToUpdt.city = clientToUpdt.city ? clientToUpdt.city : '';
    clientToUpdt.avatar = clientToUpdt.avatar ? clientToUpdt.avatar : '';
  */
};

const toUpdateAdditionalInfoInput = (obj) => {
  return {
    firstName: obj.firstName,
    lastName: obj.lastName,
    email: obj.email,
    tel: obj.tel,
    additionalInfo: obj.additionalInfo,
  };
};

const ClientInput = {
  createClientInputFromStore,
  toClientInput,
  clientRenewInput,
  toUpdateAdditionalInfoInput,
};

export default ClientInput;
