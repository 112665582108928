export const MIN_PROMO = {
  defaultLabel: 'Min Promo',
  labelId: 'dashboard.das.MinPromo',
  color: '#FD9A06',
};
export const MAX_PROMO = {
  defaultLabel: 'Max Promo',
  labelId: 'dashboard.das.MaxPromo',
  color: '#EC7D18',
};
export const MIN_LISTINO = {
  defaultLabel: 'Min Listino',
  labelId: 'dashboard.das.MinListino',
  color: '#29BB89',
};
export const MAX_LISTINO = {
  defaultLabel: 'Max Listino',
  labelId: 'dashboard.das.MaxListino',
  color: '#007D52',
};
export const BOOKED = {
  defaultLabel: 'Venduto',
  labelId: 'dashboard.booked',
  color: '#EE440E',
  labelColor: '#D8080A',
};

export const BOOKING_THRESHOLDS = [BOOKED, MIN_PROMO, MAX_PROMO, MIN_LISTINO, MAX_LISTINO];
