import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import 'styles/input.scss';

/**
 * Default switch
 * based on material-ui Switch
 * Unknown props are passed down to the underlying MUI component
 * @component
 * @see {@link https://material-ui.com/api/switch/ Switch API}
 */
const CrmInputRadioButton = (props) => {
  const { extraClass, label, required, disabled, options, onChange, value, canResetChoice, defaultValue, ...rest } = props;
  const newValue = value !== null ? (value === '' ? defaultValue : typeof value === 'object' ? value.value : value) : null;

  const [check, setCheck] = React.useState(newValue);

  const myOnClick = (value) => {
    if (value === check && canResetChoice) {
      setCheck('');
    } else {
      setCheck(value);
    }
  };

  return (
    <div className='MuiFormControl-root MuiTextField-root input-text'>
      <label className='MuiFormLabel-root MuiInputLabel-root medium smaller-font'>{check ? <span style={{ fontWeight: '700' }}>{label}</span> : <span>{label}</span>}</label>
      <RadioGroup row aria-label={label} name={label} value={newValue} onChange={onChange} className={`input-text ${extraClass}`}>
        {options.map((el) => {
          return (
            <FormControlLabel
              key={el.value}
              value={el.value}
              control={
                <Radio
                  required={required}
                  checked={check === el.value}
                  disableRipple={true}
                  onClick={() => {
                    myOnClick(el.value);
                  }}
                />
              }
              label={(check === el.value) !== null && el.value !== undefined ? <span style={{ fontWeight: '700' }}>{el.label}</span> : <span>{el.label}</span>}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

CrmInputRadioButton.defaultProps = {
  variant: 'standard',
  extraClass: '',
  label: '',
  value: '',
  required: false,
  disabled: false,
  canResetChoice: true,
  defaultValue: '',
};

/** prop types */
CrmInputRadioButton.propTypes = {
  /** extraClass: additional className */
  extraClass: PropTypes.string,
  /** label: for the input */
  label: PropTypes.string,
  /** canResetChoice: for permit to reset choice */
  canResetChoice: PropTypes.bool,
  /** value: actual value */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** variant: string, based on material-ui input variants. Must be one of ["standard", "filled", "outlined"] */
  variant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  /** required: boolean, required input */
  required: PropTypes.bool,
  /** disabled: boolean, disabled input */
  disabled: PropTypes.bool,
};

export default CrmInputRadioButton;
