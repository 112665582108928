export const LOGGED_USER = 'user';
export const VENDOR_MANAGER = 'vendor_manager';
export const ADMIN = 'admin';
export const LOGGED_CLIENT = 'client';
export const SYSTEM = 'system';
export const ACCOUNT_MANAGER = 'account_manager';
export const VENDOR = 'vendor';
export const FRONT_OFFICE = 'front_office';
export const PROPRIETA = 'proprieta';
export const CONFIGURATION_MANAGER = 'configuration_manager';

export const CREATE_USER_ROLES = [VENDOR_MANAGER, ACCOUNT_MANAGER, VENDOR, FRONT_OFFICE, PROPRIETA];

export const ENABLE_NEWSLETTER_POLICY = [VENDOR_MANAGER, VENDOR];

export const PERMISSION = {
  home: [ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER, FRONT_OFFICE, ADMIN, PROPRIETA],
  apps: [ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER, FRONT_OFFICE, ADMIN, PROPRIETA],
  clients: [ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER, FRONT_OFFICE, ADMIN, PROPRIETA],
  userInfoManager: [ACCOUNT_MANAGER, VENDOR_MANAGER, ADMIN],
  accountManager: [ACCOUNT_MANAGER, ADMIN],
  calendar: [ACCOUNT_MANAGER, VENDOR, VENDOR_MANAGER, FRONT_OFFICE, ADMIN, PROPRIETA],
  quoteList: [VENDOR, VENDOR_MANAGER, ADMIN, PROPRIETA],
  'gestione-prop': [VENDOR, VENDOR_MANAGER, ADMIN, PROPRIETA],
  'documenti-hc': [VENDOR, VENDOR_MANAGER, ADMIN],
};
