import { ACCOUNT_MANAGER, PROPRIETA } from 'constants/PagePermission';
import { useStore } from 'store/storeUtils';

export const useIsProperty = () => {
  const store = useStore();
  return store.loggedUser.role === PROPRIETA;
};

export const useIsAccountManager = () => {
  const store = useStore();
  return store.loggedUser.role === ACCOUNT_MANAGER;
};
