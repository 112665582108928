import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList, Cell } from 'recharts';
import colors from '../../styles/colors.module.scss';

const renderCustomizedLabelName = (props) => {
  const { x, y, value } = props;
  return (
    <text x={x + 1} y={y - 10}>
      {value}
    </text>
  );
};

const renderCustomizedLabelPercRegisteredUser = (props) => {
  const { x, y, width, value } = props;
  return (
    <text x={x + width - 35} y={y - 10} fontSize='14' textAnchor='end'>
      {value}
    </text>
  );
};

const renderCustomizedNumberRegisteredUser = (props) => {
  const { x, y, width, value } = props;
  return (
    <text x={x + width} y={y - 10} fontSize='16' fontWeight='Bold' textAnchor='end'>
      {value}
    </text>
  );
};

const RegisteredUsersView = (props) => {
  const { data, from, to } = props;
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    //when from & to change, reset users
    setUsers(data());
  }, [from, to]);

  return (
    <div>
      <ResponsiveContainer height={350}>
        <BarChart layout='vertical' data={users} stackOffset='expand'>
          <XAxis hide type='number' />
          <YAxis hide type='category' dataKey='name' stroke='#000000' fontSize='14' />
          <Bar dataKey='number' barSize={25} stackId='a'>
            {users.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors.graph_third_color} />
            ))}
            <LabelList dataKey='name' position='top' content={renderCustomizedLabelName} />
          </Bar>
          <Bar dataKey='differenceTotal' fill='#CCCCCC' stackId='a'>
            <LabelList dataKey='percentuale' position='top' content={renderCustomizedLabelPercRegisteredUser} />
            <LabelList dataKey='number' position='top' content={renderCustomizedNumberRegisteredUser} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RegisteredUsersView;
