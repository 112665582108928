import React from 'react';
import PropTypes from 'prop-types';
import CrmCard from 'components/common/CrmCard';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import 'styles/userInfoManager.scss';
import { useTranslation } from '@tecma/i18n';
/**
 * Default appBar
 * based on material-ui appBar
 * Unknown props are passed down to the MUI underlying component
 * @component
 * @see {@link https://material-ui.com/api/app-bar/ AppBar API}
 */
const CrmAppBar = (props) => {
  const { extraClasses, extraClassesTab, handleChange, value, tabList, section } = props;

  const { t } = useTranslation();
  return (
    <AppBar position='static' className={`${extraClasses}`}>
      <Tabs value={value} onChange={handleChange} classes={{ indicator: 'indicator-style' }}>
        {tabList.map((tab, idx) => (
          <Tab
            className={`${extraClassesTab}`}
            label={t(`label.tab.${tab.name}`, {
              defaultValue: tab.name,
            })}
            id={`${section} ${tab.id}`}
            key={idx}
            disableRipple={true}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

CrmCard.defaultProps = {
  extraClasses: '',
  extraClassesTab: '',
};

/** prop types */
CrmCard.propTypes = {
  /** extraClass: additional className */
  extraClasses: PropTypes.string,
  extraClassesTab: PropTypes.string,
  section: PropTypes.string.isRequired,
};

export default CrmAppBar;
