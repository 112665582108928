export const calculateAptStatus = (status, enabledStatus, label) => {
  var defaultLabel = label ? label : status;

  if (!enabledStatus) return defaultLabel;

  if (enabledStatus[status] && enabledStatus[status].enabled) {
    const customLabel = enabledStatus[status].label;
    return customLabel ? customLabel : defaultLabel;
  }

  return null;
};

export const filterAptStatus = (statusList, enabledStatus) => {
  return statusList
    .filter((opt) => {
      if (enabledStatus) return enabledStatus[opt.value] && enabledStatus[opt.value].enabled;
      return true;
    })
    .map((res) => ({ value: res.value, label: calculateAptStatus(res.value, enabledStatus, res.label) }));
};

export const filterAptStatusFlatList = (statusList, enabledStatus) =>
  filterAptStatus(
    statusList?.map((e) => ({ value: e, label: e })),
    enabledStatus,
  ).map((e) => e.value);
