import { useStore } from 'store/storeUtils';

export const useTodayDate = () => {
  const date = new Date();
  const store = useStore();

  const language = store.loggedUser.language || 'it';
  const locale = store.loggedUser.locale || 'IT';
  const localeDate = `${language}-${locale}`;
  const day = date.toLocaleDateString(localeDate, { weekday: 'long' });
  const month = date.toLocaleDateString(localeDate, { month: 'long' });
  const dateNumber = date.toLocaleDateString(localeDate, { day: 'numeric' });
  return { day, month, dateNumber };
};
