import React from 'react';
import ArrowIosIcon from '@material-ui/icons/ArrowForwardIos';
import CrmButton from 'components/common/CrmButton';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import arrowBackIcon from 'images/assets/arrow-back.svg';
import CrmSvgLoader from './CrmSvgLoader';
import { useStore } from 'store/storeUtils';
import { Trans } from '@tecma/i18n';

/**
 * Default chip
 * based on material-ui Chip
 * Unknown props are simply passed down to CrmButton
 * @component
 * @see {@link https://material-ui.com/api/chip/ Chip API}
 */
const CrmGoBackButton = (props) => {
  const { additonalActions, className, content, ...rest } = props;

  const store = useStore();
  const history = useHistory();

  const goBack = () => {
    store.setUpdEvent(false);
    history.goBack();
    additonalActions();
  };

  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  return (
    <>
      {!matchesPhone ? (
        <CrmButton onClick={goBack} className={className} {...rest} disableRipple={true}>
          <ArrowIosIcon style={{ transform: 'rotate(180deg)', width: '10px', height: '10px' }} fontSize='small' />
          &nbsp;{content}
        </CrmButton>
      ) : (
        <IconButton onClick={goBack} style={{ paddingTop: '0', paddingBottom: '0', paddingLeft: '0' }} disableRipple={true}>
          <CrmSvgLoader data={arrowBackIcon} alt='back' />
        </IconButton>
      )}
    </>
  );
};

CrmGoBackButton.propTypes = {
  /** additionalActions: actions perform after the default behavior (going back in browser history) */
  additonalActions: PropTypes.func,
  /** className */
  className: PropTypes.string,
  /** string content */
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

CrmGoBackButton.defaultProps = {
  additonalActions: () => {},
  className: '',
  content: <Trans i18nKey={'general.back'} />,
};

export default CrmGoBackButton;
