import React from 'react';
import ChartConstants from 'constants/ChartConstants';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Legend, CartesianGrid } from 'recharts';

import { Grid } from '@material-ui/core';
import Capitalize from 'utils/Capitalize';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import 'styles/charts.scss';
import { useTranslation } from '@tecma/i18n';
/**
 * VerticalBarReChart
 * based on recharts
 * @component
 * @see {@link http://recharts.org/en-US/api/BarChart  BarChart API}
 */
const VerticalBarReChart = (props) => {
  const { availableStatuses, availableColors, data, height, size } = props;

  const { t } = useTranslation();
  let newData = [];

  data.forEach((entry) => {
    let newEntry = { ...entry };
    Object.keys(entry).forEach((k) => {
      let newKey = t(`label.${k}`, { defaultValue: k });
      if (k !== newKey) {
        Object.defineProperty(newEntry, newKey, Object.getOwnPropertyDescriptor(newEntry, k));
        delete newEntry[k];
      }
    });
    newData.push(newEntry);
  });

  newData.map((stat) => {
    let translatedStat = t([`label.${stat.label}`, stat.label]);
    stat.label = translatedStat;
  });

  let myFontSize = 14;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  if (matches) {
    myFontSize = 10;
  }

  const splitMyString = (str) => {
    const splitStr = str.split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      if (splitStr[i].length > 10) {
        const moreSplit = splitStr[i].split('-');
        splitStr.splice.apply(splitStr, [i, 1].concat(moreSplit));
      }
    }
    return splitStr;
  };

  const myCapital = (str) => {
    let splitStr = str.toLowerCase().split('-');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = Capitalize(splitStr[i]);
    }
    let splitStr2 = splitStr.join('-');
    splitStr2 = splitStr2.split(' ');
    for (var j = 0; j < splitStr2.length; j++) {
      splitStr2[j] = Capitalize(splitStr2[j]);
    }
    return splitStr2.join(' ');
  };

  const getPath = (x, y, width, height) => {
    return `M${x},${y} h ${width} v ${height} h -${width}`;
  };

  const myGapArray = [];
  const gap = 8;
  const myTotalArray = [];

  if (newData && newData[0]) {
    let keys = Object.keys(newData[0]);
    keys = keys.filter((el) => el !== 'label');

    for (let i = 0; i < newData.length; i++) {
      const dataObject = newData[i];
      const smallArrayGap = [];
      smallArrayGap.push(0);

      for (let j = 0; j < keys.length - 1; j++) {
        const key = keys[j];
        const singleValue = dataObject[key];
        const oldGap = smallArrayGap[j];
        if (singleValue > 0) {
          smallArrayGap.push(oldGap + gap);
        } else {
          smallArrayGap.push(oldGap);
        }
      }
      myGapArray.push(smallArrayGap);

      let localTotal = 0;
      for (let j = 0; j < keys.length; j++) {
        localTotal = localTotal + dataObject[keys[j]];
      }
      myTotalArray.push(localTotal);
    }
  }

  const MyBarShape = (props) => {
    const arrayOfGap = myGapArray[props.index];
    let index = availableColors.indexOf(props.fill);
    let localGap = arrayOfGap[index];

    const lastValue = props.value[0];
    const newValue = props.value[1];

    let myY = props.y - localGap;

    return (
      <>
        {props.height > 0 && (
          <>
            <path d={getPath(props.x, myY, props.width, props.height)} stroke='none' fill={props.fill} />
            <text x={!matches ? props.x - 5 : props.x} y={myY + props.height} textAnchor='end' fontSize={12} fontWeight={600}>
              {newValue - lastValue}
            </text>
          </>
        )}
      </>
    );
  };

  const CustomizedTick = (props) => {
    const totalLabel = myTotalArray[props.index];
    let label = myCapital(props.payload.value);
    let mySplittedLable = [];
    if (matches) {
      mySplittedLable = splitMyString(label);
    }

    return (
      <>
        {totalLabel > 0 ? (
          <>
            <text x={props.x} y={props.y + 20} textAnchor='middle' fontSize={myFontSize} fontWeight={400}>
              {!matches && <tspan>{label}</tspan>}
              {matches &&
                mySplittedLable.length > 1 &&
                mySplittedLable.map((value, index) => {
                  return (
                    <tspan key={index} dy={15 * index} x={props.x}>
                      {value}
                    </tspan>
                  );
                })}
              {matches &&
                mySplittedLable.length === 1 &&
                mySplittedLable.map((value, index) => {
                  return (
                    <tspan key={index} dy={8} x={props.x}>
                      {value}
                    </tspan>
                  );
                })}
            </text>
            <text x={props.x} y={!matches ? props.y + 40 : props.y + 55} textAnchor='middle' fontSize={14} fontWeight={900} fontFamily={'Lato'}>
              {totalLabel}
            </text>
          </>
        ) : (
          <text x={props.x} y={props.y + 20} textAnchor='middle' fontSize={myFontSize} fontWeight={400}>
            {!matches && <tspan>{label}</tspan>}
            {matches &&
              mySplittedLable.length > 1 &&
              mySplittedLable.map((value, index) => {
                return (
                  <tspan key={index} dy={15 * index} x={props.x}>
                    {value}
                  </tspan>
                );
              })}
          </text>
        )}
      </>
    );
  };

  const legendData = (newData || []).reduce((acc, cur) => {
    if (cur) {
      Object.entries(cur).forEach((el) => {
        if (el && el[0]) acc[el[0]] = (el[1] || 0) + (acc[el[0]] || 0);
        return acc;
      });
    }
    return acc;
  }, {});

  return (
    newData &&
    newData.length > 0 && (
      <ResponsiveContainer width='100%' height={height * 0.9} className='vertical-bar-container'>
        <BarChart data={newData} maxBarSize={size}>
          <XAxis interval={0} tickLine={false} axisLine={false} dataKey='label' tick={<CustomizedTick />} height={!matches ? 45 : 60} minTickGap={0} />
          <YAxis axisLine={false} tickLine={false} hide={true} domain={[0, Math.max(...myTotalArray) + 20]} />
          <CartesianGrid strokeDasharray='4' stroke='#E8E9EB' strokeWidth='1px' vertical={false} />

          {availableStatuses.map((stat, i) => {
            let translatedStat = t(`label.${stat}`, { defaultValue: stat });
            return <Bar stackId='a' key={translatedStat} dataKey={translatedStat} fill={availableColors[i]} radius={0} isAnimationActive={true} shape={<MyBarShape />} />;
          })}
          <Legend
            align='center'
            content={(p) => {
              return (
                <>
                  <Grid container justifyContent='center' className='vertical-bar-legend-container'>
                    {p.payload.map((pay, i) => {
                      return (
                        <Grid key={'i' + i} item>
                          <div className='vertical-bar-legend-item'>
                            <div className='donut-legend-icon' style={{ backgroundColor: pay.color }}></div>
                            <div className='small-font-14 regular'>
                              {`${Capitalize(t([`label.${pay.payload.dataKey}`, pay.payload.dataKey]))}`} (
                              {legendData[Capitalize(pay.payload.dataKey)] ? legendData[Capitalize(pay.payload.dataKey)] : 0})
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  );
};

VerticalBarReChart.propTypes = {
  /** availableStatuses: list of all statuses to display */
  availableStatuses: PropTypes.arrayOf(PropTypes.string),
  /** availableColors: colors of each bar, depending on statuses */
  availableColors: PropTypes.arrayOf(PropTypes.string),
  /** data */
  data: PropTypes.arrayOf(PropTypes.object),
  /** height: of the chart */
  height: PropTypes.number,
  /** width: of the chart */
  width: PropTypes.number,
  /** radius: a 4 elements array defining the border radius of bars */
  radius: PropTypes.arrayOf(PropTypes.number),
  /** size of the bars of the chart */
  size: PropTypes.number,
};

VerticalBarReChart.defaultProps = {
  availableStatuses: ChartConstants.availableStatus,
  availableColors: ChartConstants.defaultColors,
  data: [],
  height: 270,
  width: 500,
  radius: [0, 0, 0, 0],
  size: 50,
};

export default VerticalBarReChart;
