import { memo } from 'react';

export const IconAddEvent = memo(() => (
  <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2050_78882)'>
      <path
        d='M26.5 5H6.5C5.94772 5 5.5 5.44771 5.5 5.99999V25.9997C5.5 26.552 5.94772 26.9997 6.5 26.9997H26.5C27.0523 26.9997 27.5 26.552 27.5 25.9997V5.99999C27.5 5.44771 27.0523 5 26.5 5Z'
        stroke='#6E748C'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M22.5 3V7' stroke='#6E748C' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.5 3V7' stroke='#6E748C' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 11H27.5' stroke='#6E748C' strokeLinecap='round' strokeLinejoin='round' />
      <g clipPath='url(#clip1_2050_78882)'>
        <path
          d='M26.5 5H6.5C5.94772 5 5.5 5.44771 5.5 5.99999V25.9997C5.5 26.552 5.94772 26.9997 6.5 26.9997H26.5C27.0523 26.9997 27.5 26.552 27.5 25.9997V5.99999C27.5 5.44771 27.0523 5 26.5 5Z'
          stroke='#6E748C'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_2050_78882'>
        <rect width='32' height='31.9996' fill='white' transform='translate(0.5)' />
      </clipPath>
      <clipPath id='clip1_2050_78882'>
        <rect width='32' height='31.9996' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
));
