import { clone } from 'lodash';

/**
 * a string formatter to replace and array of characters with another character
 */
const defaultOptions = {
  toLower: true,
  toUpper: false,
  toBeReplaced: [/\_/g, /\./g],
  replaceWith: '-',
};

const NameFormatter = (value, options = {}) => {
  // if value is not a string or it is undefined, don't even process it
  if (!value || typeof value !== 'string') return '';
  let finalValue = clone(value);

  // merge options with default options, and check mandatory fields
  const { toLower, toUpper, toBeReplaced, replaceWith } = Object.assign({ ...defaultOptions }, options);
  if (!toBeReplaced || !Array.isArray(toBeReplaced) || toBeReplaced.length === 0) return finalValue;
  if (!replaceWith || typeof replaceWith !== 'string') return finalValue;

  // apply replaces
  for (let i in toBeReplaced) finalValue = finalValue.replace(toBeReplaced[i], replaceWith);

  // apply transformations
  if (toLower) finalValue = finalValue.toLowerCase();
  if (toUpper) finalValue = finalValue.toUpperCase();

  return finalValue;
};

export default NameFormatter;
