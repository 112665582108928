import React from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'utils/MyMoment';
import PropTypes from 'prop-types';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import 'styles/dateRange.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useStore } from 'store/storeUtils';
import * as rdrLocales from 'react-date-range/dist/locale';
import i18n, { useTranslation } from '@tecma/i18n';
import { DatesFormatterRange } from 'utils/DatesFormatter';

const CrmCalendarRange = (props) => {
  const { start, end, min, max, updateEnd, updateStart, extraClasses, disabled } = props;

  const store = useStore();
  const { t } = useTranslation();
  const [calendarVisible, setCalendarVisible] = React.useState(false);

  const [weekSelected, setWeekSelected] = React.useState(false);
  const [monthSelected, setMonthSelected] = React.useState(false);
  const [numberOfClick, setNumberOfClick] = React.useState(0);

  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const [selectionRange, setSelectionRange] = React.useState({
    startDate: start.toDate(),
    endDate: end.toDate(),
    key: 'selection',
  });

  React.useEffect(() => {
    setSelectionRange({
      startDate: start.toDate(),
      endDate: end.toDate(),
      key: 'selection',
    });
  }, [start, end]);

  const handleSelect = (ranges) => {
    setNumberOfClick(numberOfClick + 1);

    let startDate = ranges.selection.startDate;
    let endDate = ranges.selection.endDate;

    let lastSunday = new Date();
    lastSunday.setHours(0, 0, 0, 0);
    let diff = lastSunday.getDate() - lastSunday.getDay() + (lastSunday.getDay() === 0 ? -7 : 0) - 7;
    lastSunday.setDate(diff);
    let endOfLastWeek = new Date();
    diff = diff + 6;
    endOfLastWeek.setDate(diff);
    endOfLastWeek.setHours(0, 0, 0, 0);

    let date = new Date();
    let firstDayOfLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    let lastDayOfLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);

    if (lastSunday.getTime() === startDate.getTime() && endOfLastWeek.getTime() === endDate.getTime()) {
      setWeekSelected(true);
    } else if (firstDayOfLastMonth.getTime() === startDate.getTime() && lastDayOfLastMonth.getTime() === endDate.getTime()) {
      setMonthSelected(true);
    } else {
      setWeekSelected(false);
      setMonthSelected(false);
    }
    updateStart(moment(startDate));
    updateEnd(moment(endDate));

    if (endDate.getTime() !== startDate.getTime() || numberOfClick === 1) {
      setCalendarVisible(!calendarVisible);
      setNumberOfClick(0);
    }
  };

  const selectLastWeek2 = () => {
    let ranges = {
      startDate: null,
      endDate: null,
      key: 'selection',
    };
    if (weekSelected === false) {
      let lastSunday = new Date();
      let diff = lastSunday.getDate() - lastSunday.getDay() + (lastSunday.getDay() === 0 ? -7 : 0) - 7;
      lastSunday.setDate(diff);
      lastSunday.setHours(0, 0, 0, 0);
      let endOfLastWeek = new Date();
      diff = diff + 6;
      endOfLastWeek.setDate(diff);
      endOfLastWeek.setHours(0, 0, 0, 0);
      ranges.startDate = lastSunday;
      ranges.endDate = endOfLastWeek;
    } else {
      let endOfCurrent = new Date();
      endOfCurrent.setHours(0, 0, 0, 0);
      const startOfCurrent = new Date(2021, 0, 1, 0, 0, 0);
      ranges.startDate = startOfCurrent;
      ranges.endDate = endOfCurrent;
    }
    handleSelect({ selection: ranges });
    setWeekSelected(!weekSelected);
    setMonthSelected(false);
    setCalendarVisible(false);
  };

  const selectLastMonth2 = () => {
    let ranges = {
      startDate: null,
      endDate: null,
      key: 'selection',
    };
    if (monthSelected === false) {
      let date = new Date();
      let firstDayOfLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      let lastDayOfLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);
      ranges.startDate = firstDayOfLastMonth;
      ranges.endDate = lastDayOfLastMonth;
    } else {
      let endOfCurrent = new Date();
      endOfCurrent.setHours(0, 0, 0, 0);
      const startOfCurrent = new Date(2021, 0, 1, 0, 0, 0);
      ranges.startDate = startOfCurrent;
      ranges.endDate = endOfCurrent;
    }
    handleSelect({ selection: ranges });
    setWeekSelected(false);
    setMonthSelected(!monthSelected);
    setCalendarVisible(false);
  };

  const dayToFocus = new Date();
  dayToFocus.setMonth(dayToFocus.getMonth() - 1);

  return (
    <div className={`position-of-tuning-date-range ${extraClasses}`}>
      {matchesPhone && (
        <div className='date-range-container'>
          <div className={monthSelected ? 'date-range-selected' : 'date-range'} onClick={selectLastMonth2}>
            {t('calendarRange.lastMonth')}
          </div>
          <div className={weekSelected ? 'date-range-selected' : 'date-range'} onClick={selectLastWeek2}>
            {t('calendarRange.lastWeek')}
          </div>
        </div>
      )}
      {!matchesPhone && (
        <Button
          className={calendarVisible ? 'open-calendar-button-focus' : 'open-calendar-button'}
          disabled={disabled}
          disableRipple={true}
          onClick={() => {
            setCalendarVisible(!calendarVisible);
          }}
        >
          {DatesFormatterRange(start.toDate(), end.toDate()).split('/').join('-')}
          <CalendarTodayOutlinedIcon style={{ fontSize: '14px', color: '#989898', paddingLeft: '15px' }} />
        </Button>
      )}
      {calendarVisible && (
        <DateRange
          locale={rdrLocales[i18n.language] || rdrLocales[store.loggedUser.language]}
          ranges={[selectionRange]}
          onChange={handleSelect}
          shownDate={dayToFocus}
          minDate={min}
          maxDate={max}
          months={2}
          direction={'horizontal'}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          className='date-range-calendar'
          classNames={{
            prevButton: 'customPrevButtonClassName',
            nextButton: 'customNextButtonClassName',
          }}
        />
      )}
    </div>
  );
};

CrmCalendarRange.defaultProps = {};
/** prop types */
CrmCalendarRange.propTypes = {
  /** start: selection start date */
  start: PropTypes.instanceOf(moment),
  /** end: selection end date */
  end: PropTypes.instanceOf(moment),
  /** min: selection minimum date */
  min: PropTypes.instanceOf(Date),
  /** max: selection maximum date */
  max: PropTypes.instanceOf(Date),
  /** updateStart: function to update the start of selection */
  updateStart: PropTypes.func,
  /** updateEnd: function to update the end of selection */
  updateEnd: PropTypes.func,
};

export default CrmCalendarRange;
