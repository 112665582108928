/**
 * a generic function to capitalize strings
 * @param {string} string what must be capitalized
 */
const Capitalize = (string) => {
  if (!string) return undefined;
  return string.replace(/^\w/, (c) => c.toUpperCase()).replace('_', ' ');
};

export const ForcedCapitalize = (string) => {
  if (!string) return undefined;
  return string
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase())
    .replace('_', ' ');
};

export const CapitalizeSentence = (str) => {
  if (!str) return '';
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = Capitalize(splitStr[i]);
  }
  return splitStr.join(' ');
};

export const FormatString = (str) => {
  let formattedStr = str.replace(/-/g, ' ');
  formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
  return formattedStr;
};

export default Capitalize;
